import React, { useEffect, useRef, useContext, useState } from "react";
import styled from "styled-components";
import { PiNavigationArrowFill } from "react-icons/pi";
import { TbPencil } from "react-icons/tb";
import { theme } from "../../Theme";
import { ChatboxContext } from "../ChatContext";

const InputArea = () => {
	const [textareaValue, setTextareaValue] = useState("");
	const textareaRef = useRef(null);
	const messagesContainerRef = useRef(null);

	const {
		setCombinedText,
		submitButtonDisabled,
		setMessages,
		setIsTyping,
		messages,
		setPrompt,
	} = useContext(ChatboxContext);

	const newConversation = async () => {
		try {
			const newConvo =
				await window.sdkInstance.agent.newConversationWithMessage(messages);
			console.log(newConvo);
			setCombinedText([]);
		} catch (error) {
			console.error(error);
		}
	};

	const autoResize = (textarea) => {
		textarea.style.height = "auto";
		textarea.style.height = textarea.scrollHeight + "px";
	};

	useEffect(() => {
		const handleTyping = () => {
			setIsTyping(true);
		};

		const handleKeyDown = (e) => {
			if (e.key === "Enter" && !submitButtonDisabled) {
				e.preventDefault();
				setPrompt(textareaValue);
				setTextareaValue("");
			}
		};
		const textarea = textareaRef.current;

		if (textarea) {
			textarea.addEventListener("keydown", handleKeyDown);
			textarea.addEventListener("input", handleTyping);
			setTextareaValue("");
		}
		if (messagesContainerRef.current) {
			messagesContainerRef.current.scrollTop =
				messagesContainerRef.current.scrollHeight;
		}

		return () => {
			if (textarea) {
				textarea.removeEventListener("keydown", handleKeyDown);
				setTextareaValue("");
			}
		};
	}, [
		setPrompt,
		setIsTyping,
		submitButtonDisabled,
		textareaRef,
		textareaValue,
	]);

	return (
		<div>
			<Input>
				<Top>
					<button
						onClick={newConversation}
						value={messages}
						onChange={() => {
							setMessages("");
						}}
						className="dialog"
					>
						<p>
							<Pencil />
							New Conversation
						</p>
					</button>
				</Top>
				<Text>
					<Textarea
						id="autoresizing"
						spellCheck={true}
						value={textareaValue}
						lang="en-US"
						tabIndex={0}
						rows={0}
						placeholder="What questions do you have..."
						onChange={(e) => {
							setMessages("");
							setTextareaValue(e.target.value);
							autoResize(e.target);
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter" && !submitButtonDisabled) {
								e.preventDefault();
								setTextareaValue("");
								setPrompt(textareaValue);
							}
						}}
					/>
					<Icons>
						<button
							type="submit"
							onClick={() => {
								setPrompt(textareaValue);
								setTextareaValue("");
							}}
							disabled={submitButtonDisabled}
						>
							<ArrowIcon disabled={submitButtonDisabled} />
						</button>
					</Icons>
				</Text>
				<Enter>
					<p>Return or Enter to send</p>
				</Enter>
			</Input>
		</div>
	);
};

export default InputArea;

const Top = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 0.4rem;

	.dialog {
		display: flex;
		align-items: center;
		color: var(--text-200);
		padding: 0.3rem;
		border-radius: 0.4rem;
		outline: none;
		border: none;
	}

	.dialog {
		background-color: transparent;
		cursor: pointer;
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		.dialog {
			font-size: 0.7rem;
		}
	}
`;

const Pencil = styled(TbPencil)`
	font-size: 0.95rem;
	position: relative;
	top: 0.1rem;
`;

const Input = styled.div`
	padding: 1rem;
	border-color: hsla(0, 0%, 100%, 0.2);
`;

const Text = styled.div`
	background-color: var(--bg-100);
	border-color: rgba(32, 33, 35, 0.5);
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.4rem 0;
	position: relative;
`;

const Textarea = styled.textarea`
	font-family: "Inter", sans-serif;
	outline: none;
	background-color: var(--bg-100);
	width: 55vw;
	padding: 0 1.5rem;
	align-content: center;
	resize: none;
	border: none;
	color: var(--text-400);
	font-size: 0.9rem;
	font-size: 16px !important;

	&:focus {
		outline: none;
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		width: 89vw;
	}
`;

const Icons = styled.div`
	display: flex;
	gap: 0.3rem;
	margin-right: 1rem;

	button {
		outline: none;
		border: none;
		background-color: transparent;
	}
`;

const ArrowIcon = styled(PiNavigationArrowFill)`
	font-size: 1.3rem;
	transform: rotate(45deg);
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
	color: ${({ disabled }) => (disabled ? "gray" : "black")};
`;

const Enter = styled.div`
	color: grey;
	display: flex;
	justify-content: flex-end;
	font-size: 0.8rem;

	@media (max-width: ${theme.breakpoints.sm}) {
		font-size: 0.7rem;
	}
`;
