import React, { useEffect, useContext } from "react";
import { ChatboxContext } from "./ChatContext";
import styled from "styled-components";
import { theme } from "../Theme";
import { VscSparkle } from "react-icons/vsc";
import { IoMdClose } from "react-icons/io";
import { useMediaQuery } from "react-responsive";

const Suggestions = ({ suggestionsRef, setShowSuggestions }) => {
	const {
		isListSet,
		setIsListSet,
		isSuggestionsVisible,
		list,
		setMessages,
		setIsSuggestionsVisible,
		setAssistantChat,
		messages,
		setPrompt,
	} = useContext(ChatboxContext);

	const closeModal = () => {
		setIsSuggestionsVisible(false);
	};

	const handleSuggestions = async (suggestionList) => {
		try {
			setIsListSet(true);
			setIsSuggestionsVisible(true);
			setAssistantChat({ type: "suggestions", content: suggestionList });
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (isSuggestionsVisible && suggestionsRef.current) {
			suggestionsRef.current.scrollTop = suggestionsRef.current.scrollHeight;
		}
	}, [isSuggestionsVisible, suggestionsRef]);

	const handleSuggestionClick = (suggestion) => {
		setMessages(suggestion);
		console.log(messages);
		setIsSuggestionsVisible(false);
		setPrompt(suggestion);
	};

	useEffect(() => {
		if (isListSet) {
			setIsListSet(false);
		}
	}, [isListSet, setIsListSet]);

	const sideVariants = {
		open: {
			width: "60vw",
			transition: { duration: 0.7 },
		},
		closed: {
			width: "0rem",
			transition: { duration: 0.7 },
		},
	};

	const isSmallScreen = useMediaQuery({ maxWidth: 1100 });

	return (
		<div ref={suggestionsRef}>
			{isSmallScreen && list ? (
				<div>
					<Title>Suggestions</Title>
					<List
						variants={sideVariants}
						initial="closed"
						animate="open"
						exit="closed"
					>
						<SuggestionsContainer>
							<Ul>
								{list?.suggestions?.map((suggestion, index) => (
									<>
										<Li
											key={`suggestion-${index}`}
											onClick={() => {
												handleSuggestionClick(suggestion);
												setIsSuggestionsVisible(false);
												setShowSuggestions(false);
											}}
										>
											<p>{suggestion}</p>
										</Li>
									</>
								))}
							</Ul>
						</SuggestionsContainer>
					</List>
				</div>
			) : (
				<>
					<TopContainer>
						<Button
							value={messages}
							onClick={handleSuggestions}
							onChange={(e) => {
								setMessages(e.target.value);
							}}
							visiblesuggestion={isSuggestionsVisible ? "true" : "false"}
						>
							<Sparkle /> Suggestions
						</Button>
						{isSuggestionsVisible && list && (
							<CloseButton onClick={closeModal}>
								<IoMdClose />
							</CloseButton>
						)}
					</TopContainer>
					{isSuggestionsVisible && list && (
						<BigList
							variants={sideVariants}
							initial="closed"
							animate="open"
							exit="closed"
						>
							<SuggestionsContainer>
								<Ul>
									{list.suggestions.map((suggestion, index) => (
										<Li
											key={index}
											onClick={() => {
												handleSuggestionClick(suggestion);
												setIsSuggestionsVisible(false);
											}}
										>
											<p>{suggestion}</p>
										</Li>
									))}
								</Ul>
							</SuggestionsContainer>
						</BigList>
					)}
				</>
			)}
		</div>
	);
};

export default Suggestions;

const SuggestionsContainer = styled.div`
	flex-wrap: wrap;
	overflow-y: scroll;
	font-size: 0.8rem;
	height: 20rem;
	width: 18rem;

	&::-webkit-scrollbar {
		width: 0.5em;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
	}

	scrollbar-width: thin;
	scrollbar-color: transparent;

	@media (max-width: ${theme.breakpoints.lg}),
		(max-width: ${theme.breakpoints.md}),
		(max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.xs}) {
		height: 30rem;
		width: 30rem;
	}

	@media (max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.xs}) {
		height: 20rem;
		width: 15rem;
	}
`;

const Title = styled.h2`
	color: var(--text-100);
	text-align: center;
	margin-top: 2rem;
`;

const Ul = styled.ul`
	list-style: none;
	padding: 0.3rem 0rem;
	overflow-y: auto;
	/* border: solid red 1px; */
`;

const Li = styled.li`
	border: 1px solid var(--text-100);
	margin: 0.9rem 0rem;
	padding: 0.5rem 0.7rem;
	cursor: pointer;
	border-radius: 0.5rem;
	color: var(--text-100);
	width: fit-content;
	/* white-space: normal;
	overflow-wrap: break-word;
	word-break: break-word; */
	transition: background-color 0.3s ease, border-radius 0.3s ease;

	&:hover {
		background-color: lightgrey;
		color: black;
	}
`;

const Button = styled.button`
	color: ${(props) =>
		props.visiblesuggestion === "true" ? "var(--text-100)" : "var(--text-200)"};
	background-color: ${(props) =>
		props.visiblesuggestion === "true" ? "#722188" : "var(--bg-100)"};
	padding: 0.2rem 0.5rem 0.4rem;
	border-radius: 0.4rem;
	outline: none;
	border: none;
	cursor: pointer;
	text-align: center;

	@media (max-width: ${theme.breakpoints.sm}) {
		font-size: 0.7rem;
	}
`;

const List = styled.div`
	border-radius: 0.7rem;
	color: var(--text-100);

	@media (max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.md}) {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		height: 78vh;
		font-size: 0.8rem;
	}

	@media (max-width: ${theme.breakpoints.lg}),
		(max-width: ${theme.breakpoints.xl}) {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		height: 78vh;
		overflow-y: scroll;
	}
`;

const BigList = styled.div`
	color: var(--text-100);
	animation: dropdown 0.5s ease-in 0s 1 normal forwards;
	overflow-y: scroll;

	@media (max-width: ${theme.breakpoints.sm}) {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto;
		height: 80vh;
		font-size: 0.8rem;
	}

	@keyframes dropdown {
		0% {
			opacity: 0;
			transform: translateY(-30px);
		}

		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
`;

const TopContainer = styled.div`
	display: flex;
	justify-content: space-between;
	border-top: solid var(--text-100) 1px;
	padding-top: 1rem;
	/* padding-bottom: 1rem; */

	h2 {
		color: white;
		text-align: center;
	}

	@media (max-width: ${theme.breakpoints.xs}) {
		border-top: none;

		button {
			display: none;
		}
	}
`;

const CloseButton = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	font-size: 1rem;
	color: var(--text-100);
`;

const Sparkle = styled(VscSparkle)`
	margin-right: 0.5rem;
	transform: rotate(90deg);
	font-size: 1rem;
	position: relative;
	top: 0.2rem;
`;
