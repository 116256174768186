import React, { useContext, useEffect } from "react";
import { useTheme } from "../../ThemeContext";
import { ChatboxContext } from "../ChatContext";
import Checkbox from "../Checkbox";

const Appearance = () => {
	const { currentTheme, toggleTheme } = useTheme();
	const { checkboxes, setCheckboxes } = useContext(ChatboxContext);

	useEffect(() => {
		if (currentTheme === "light") {
			document
				.querySelector("html")
				.setAttribute("data-theme", "light-dashboard");
		} else {
			document.querySelector("html").removeAttribute("data-theme");
		}
	}, [currentTheme]);

	const toggleCheckbox = (checkboxName) => {
		setCheckboxes((prevCheckboxes) => ({
			...prevCheckboxes,
			[checkboxName]: !prevCheckboxes[checkboxName],
		}));

		if (checkboxName === "switchToLightMode") {
			toggleTheme();
		}
	};

	return (
		<div>
			<div className="second">
				<Checkbox
					checked={checkboxes.switchToLightMode}
					onChange={() => toggleCheckbox("switchToLightMode")}
					currentTheme={currentTheme}
				/>
			</div>
		</div>
	);
};

export default Appearance;
