import React, { useState } from "react";
import { data } from "./SourceButton";
import styled from "styled-components";

const Sources = () => {
	const [sources, setSources] = useState(data);

	console.log(sources);

	return (
		<div>
			{sources.button_groups.map((items, i) => (
				<ButtonContainer key={i}>
					<Text>- {items.title}</Text>
					{items.buttons.map(
						(button, index) =>
							button.text && (
								<StyledButton key={index}>{button.text}</StyledButton>
							)
					)}
				</ButtonContainer>
			))}
		</div>
	);
};

export default Sources;

const ButtonContainer = styled.div`
	color: white;
`;

const StyledButton = styled.button`
	background-color: #02ffb3;
	color: black;
	padding: 0.5rem;
	width: fit-content;
	border-radius: 0.4rem;
`;

const Text = styled.p`
	font-size: 1.2rem;
	/* border-bottom: solid green 1px; */
`;
