import React, { useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import AI from "../../../assets/AI.png";
import { BiError } from "react-icons/bi";
import InputArea from "./InputArea";
import { theme } from "../../Theme";
import Feedback from "./Feedback";
import people from "../../../assets/people.png";
import moment from "moment";
import { ChatboxContext } from "../ChatContext";
import VideoIframe from "./VideoIframe";
import Sources from "../buttons/Sources";
import Clarification from "../buttons/Clarification";

const parseContent = (content) => {
	let cleanedContent = content
		.replace(/\*\*/g, "")
		.replace(/^-/gm, "• ")
		.replace(/^ {2}-/gm, "• ");

	const lines = cleanedContent.split("\n");
	return lines.map((line, index) => {
		if (line.startsWith("### ")) {
			return (
				<p
					key={index}
					style={{
						fontWeight: "bolder",
						marginTop: "2rem",
						fontSize: "1.2rem",
					}}
				>
					{line.slice(4)}
				</p>
			);
		} else if (line.startsWith("#### ")) {
			return (
				<p
					key={index}
					style={{
						fontWeight: "bolder",
						marginTop: "2rem",
						fontSize: "1.2rem",
					}}
				>
					{line.slice(5)}
				</p>
			);
		} else if (line.startsWith("• ")) {
			return (
				<div
					style={{
						textIndent: "0.3rem",
					}}
				>
					<p
						key={index}
						style={{
							marginLeft: "1rem",
						}}
					>
						{line}
					</p>
				</div>
			);
		} else {
			return <p key={index}>{line}</p>;
		}
	});
};

const ChatMessage = () => {
	const messagesContainerRef = useRef(null);
	const userMessageRefs = useRef([]);
	const InitialValue = useRef(null);

	const {
		list,
		setList,
		combinedText,
		isLoading,
		error,
		visiblePacifier,
		setVisiblePacifier,
		videoError,
		video,
		displayAvatar,
	} = useContext(ChatboxContext);

	const today = new Date();
	const dateOptions = {
		month: "short",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		seconds: "numeric",
	};

	useEffect(() => {
		if (userMessageRefs.current.length > 0) {
			const lastUserMessage =
				userMessageRefs.current[userMessageRefs.current.length - 1];
			if (lastUserMessage) {
				messagesContainerRef.current.scrollTop = lastUserMessage.offsetTop;
			}
		}
	}, [combinedText]);

	useEffect(() => {
		if (InitialValue.current) {
			return;
		}
		InitialValue.current = displayAvatar;
	}, [displayAvatar]);

	useEffect(() => {
		const id = setTimeout(() => {
			if (!list.length) {
				window.sdkInstance.file.getFaqList().then((list) => {
					if (list) {
						setList(list);
					} else {
						window.sdkInstance.triggerError("auth", "login", "no faq list");
					}
				});
			}
		}, 1000);

		return () => {
			clearTimeout(id);
		};
	}, [list.length, setList]);

	useEffect(() => {
		let index = 0;
		const pacifierCount = list.pacifiers?.length;

		const showPacifier = () => {
			setVisiblePacifier(list.pacifiers[index]);
			index = (index + 1) % pacifierCount;

			setTimeout(() => {
				setVisiblePacifier(null);
				showPacifier();
			}, 6000);
		};

		if (pacifierCount > 0) {
			setTimeout(showPacifier, 4500);
		}
	}, [list.pacifiers, setVisiblePacifier]);

	let myUserPrompt;

	return (
		<>
			<Div>
				<MessagesContainer ref={messagesContainerRef}>
					<Greeting>
						<Messages>
							<div className="cont">
								<AssistantImage src={AI} alt="assistant" />
								<p className="date">
									{today.toLocaleString("en-US", dateOptions)}
								</p>
							</div>
							<p className="welcome">{list.welcome}</p>
						</Messages>
					</Greeting>
					<InnerContainer>
						{combinedText.map((message, i) => {
							const isUser = message.type === "user";
							if (isUser) {
								myUserPrompt = message.content;
							}
							const isAssistant = message.type === "assistant";
							const isLastAssistant =
								i === combinedText.length - 1 && isAssistant;
							const formattedTime = moment(message.updated_at).format(
								"D MMM, h:mm A"
							);
							let assistantContent;
							let cleanedContent;

							if (message && typeof message.content === "string") {
								try {
									assistantContent = JSON.parse(message.content);
									if (assistantContent && assistantContent.response) {
										cleanedContent = parseContent(assistantContent.response);
									}
								} catch (error) {
									cleanedContent = parseContent(message.content);
								}
							}

							return (
								<div
									key={i}
									ref={(el) => isUser && (userMessageRefs.current[i] = el)}
								>
									{isUser && (
										<UserMessageContainer>
											<UserMessage>
												{message?.content !== "Welcome" &&
												!message?.content.startsWith("//") ? (
													<>
														<div>
															<Timestamp>{formattedTime}</Timestamp>
															<GradientImage src={people} alt="user profile" />
														</div>
														<div>
															<p>{message?.content}</p>
														</div>
													</>
												) : null}
											</UserMessage>
										</UserMessageContainer>
									)}
									{isAssistant && (
										<AgentMessage islastassistant={`${isLastAssistant}`}>
											{myUserPrompt !== "Welcome" &&
											!myUserPrompt.startsWith("//") ? (
												<>
													<AssistantImage src={AI} alt="assistant" />
													<Timestamp>
														{formattedTime
															? formattedTime
															: today.toLocaleString("en-US", dateOptions)}
													</Timestamp>
												</>
											) : null}
											<div>
												{isLastAssistant && isLoading ? (
													<>
														{error ? (
															<p className="error">
																{message.error} <ErrorIcon />
															</p>
														) : (
															list.pacifiers.length > 0 && (
																<>
																	<p>{list.pacifierHeader}</p>
																	<Span className="pacifiers-container">
																		{visiblePacifier && (
																			<div className="pacifiers">
																				{visiblePacifier}
																			</div>
																		)}
																	</Span>
																</>
															)
														)}
													</>
												) : (
													<>
														<p>{cleanedContent}</p>
														<div>
															{assistantContent &&
																assistantContent.type === "buttons" &&
																(assistantContent.button_groups &&
																assistantContent.button_groups.some(
																	(group) => group.title === "Sources"
																) ? (
																	<BottomContainer>
																		{/* <Feedback
																			combinedText={combinedText}
																			content={message.content}
																		/> */}
																		<Sources
																			assistantContent={assistantContent}
																			combinedText={combinedText}
																			content={message.content}
																		/>
																	</BottomContainer>
																) : (
																	<>
																		<Clarification
																			assistantContent={assistantContent}
																		/>
																		<Feedback
																			combinedText={combinedText}
																			content={message.content}
																		/>
																	</>
																))}
														</div>
													</>
												)}
											</div>
										</AgentMessage>
									)}
								</div>
							);
						})}
					</InnerContainer>
				</MessagesContainer>

				<Bottom>
					<InputArea />
				</Bottom>
				{video?.type === "media_request" && (
					<>
						{videoError ? (
							<p className="error">{videoError}</p>
						) : (
							<VideoIframe />
						)}
					</>
				)}
			</Div>
		</>
	);
};

export default ChatMessage;

const BottomContainer = styled.div`
	/* border: solid red 1px; */
	/* display: flex;
	flex-direction: column; */
`;

const InnerContainer = styled.div`
	height: auto;
	overflow-y: scroll;
	scroll-snap-type: y mandatory;
`;

const Span = styled.div`
	background: rgba(255, 255, 255, 0.5);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(3px);
	-webkit-backdrop-filter: blur(3px);
	border: 1px solid rgba(255, 255, 255, 0.18);
	color: var(--text-100);
	border-radius: 0.8rem;
	padding: 1rem;
	height: 8rem;
	font-style: italic;
	font-size: 1rem;

	.pacifiers {
		animation: fadeInOut 6s linear infinite;
	}

	@keyframes fadeInOut {
		0%,
		100% {
			opacity: 0;
		}

		10%,
		90% {
			opacity: 1;
		}
	}

	@media (max-width: ${theme.breakpoints.md}) {
		font-size: 0.9rem;
		height: 11rem;
		width: 18rem;
		line-height: 1.5;
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		font-size: 0.8rem;
		height: 11rem;
		width: 77vw;
		line-height: 1.5;
	}
`;

const Div = styled.div`
	display: flex;
	justify-content: center;
	margin: 0 auto;
	width: 67vw;
	height: 90vh;
	transition: 0.2s cubic-bezier(0.36, -0.01, 0, 0.77);
	overflow-y: hidden;

	@media (max-width: ${theme.breakpoints.sm}) {
		width: 90vw;
		overflow-y: hidden;
	}
`;

const Greeting = styled.div`
	color: var(--text-100);
	margin-top: 2rem;

	img {
		height: 3rem;
		width: 3rem;
		border-radius: 0.4rem;

		@media (max-width: ${theme.breakpoints.sm}) {
			height: 5vh;
			width: 5vh;
		}
	}
`;

const Messages = styled.div`
	padding-left: 1rem;
	margin-bottom: 1rem;
	border-radius: 0.5rem;

	.cont {
		display: flex;
	}

	.date {
		font-size: 0.7rem;
		line-height: 1.2;
		align-self: flex-end;
		color: var(--text-200);
	}

	.welcome {
		font-size: 0.9rem;
		line-height: 1.5;
		margin-top: 0.9rem;
	}
`;

const Timestamp = styled.span`
	font-size: 0.7rem;
	color: var(--text-200);
	margin-bottom: 0.9rem;
	padding-right: 1rem;
`;

const MessagesContainer = styled.div`
	width: 100vw;
	height: 75vh;
	overflow-y: scroll;
	margin-top: 1rem;
	background-color: var(--bg-300);
	border-radius: 0.3rem;
	scroll-snap-type: y mandatory;

	&::-webkit-scrollbar {
		width: 0.5em;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
	}

	scrollbar-width: thin;
	scrollbar-color: transparent transparent;

	@media (max-width: 1100px) {
		height: 68vh;
	}
	@media (max-width: 414px) {
		height: 68vh;
	}

	@media (max-width: 375px) {
		height: 60vh;
	}
`;

const UserMessage = styled.div`
	color: var(--text-200);
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	animation: slideUp 0.9s ease 0s 1 normal forwards;

	p {
		font-size: 0.9rem;
		line-height: 1.2;
		margin-top: 0.9rem;
		text-align: right;
	}

	@keyframes slideUp {
		0% {
			opacity: 0;
			transform: translateY(50px);
		}

		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
`;

const UserMessageContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 0.3rem 2rem;
	transition: padding 0.3s ease;
`;

const GradientImage = styled.img`
	height: 4vh;
	width: 4vh;
	padding: 0.5rem;
	border-radius: 0.4rem;
	background-color: var(--accent-100);
`;

const AgentMessage = styled.div`
	color: var(--text-100);
	padding: 1rem;
	margin-bottom: 1rem;
	border-radius: 0.5rem;
	animation: ${(props) =>
		props.islastassistant ? "slideUp 0.9s ease 0s 1 normal forwards" : ""};

	p {
		font-size: 0.9rem;
		line-height: 1.5;
		margin: 1rem 0 0.9rem 0;
	}

	img {
		height: 5vh;
		width: 5vh;
	}

	.error {
		color: var(--text-600);
		background-color: var(--bg-500);
		padding: 0.6rem;
		border-radius: 0.4rem;
		margin-top: 0.7rem;
		font-size: 1rem;
	}

	@keyframes slideUp {
		0% {
			opacity: 0;
			transform: translateY(50px);
		}

		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
`;

const ErrorIcon = styled(BiError)`
	color: red;
	margin-left: 4px;
	vertical-align: middle;
	font-size: 1.3rem;
`;

const AssistantImage = styled.img`
	height: 3rem;
	width: 3rem;
	border-radius: 0.4rem;
	margin-right: 1rem;
`;

const Bottom = styled.div`
	position: fixed;
	bottom: 0;

	@media (max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.md}),
		(max-width: ${theme.breakpoints.lg}),
		(max-width: 1100px) {
		bottom: 4rem;
	}
`;
