import React, { useContext } from "react";
import { ChatboxContext } from "../ChatContext";
import styled from "styled-components";
import { theme } from "../../Theme";
import { IoEyeSharp } from "react-icons/io5";
import Tooltip from "@mui/material/Tooltip";
import { useMediaQuery } from "react-responsive";

const Video = () => {
	const { filesList, setVideoError, setPrompt, setShowMenu, selectedOption } =
		useContext(ChatboxContext);

	const openModal = (videoUrl) => {
		setPrompt(videoUrl);
		setShowMenu(false);
	};
	console.log(selectedOption);

	const charactersLimit = 23;
	const isSmallScreen = useMediaQuery({ maxWidth: 1100 });

	try {
		return (
			<>
				<First>
					<FileContainers>
						{filesList
							.filter((item) => !item.signedUrl)
							.map((item, i) => {
								const characters =
									item.title.length > charactersLimit
										? item.title.slice(0, charactersLimit) + "..."
										: item.title;

								const videoUrl =
									selectedOption === "viewInline"
										? item.viewInline
										: selectedOption === "viewTab"
										? item.viewTab
										: item.viewPopup;

								return (
									<div key={i} className="main">
										<div className="download">
											<Tooltip
												title="View"
												placement="top-start"
												slotProps={{
													tooltip: {
														sx: {
															backgroundColor: "var(--accent-100)",
															color: "black",
															borderRadius: ".2rem",
															fontSize: ".8rem",
														},
													},
												}}
											>
												<div>
													<IoEyeSharp
														onClick={() => openModal(videoUrl)}
														className="eye"
													/>
												</div>
											</Tooltip>
										</div>
										<Tooltip
											title={item.title}
											placement="top-start"
											slotProps={{
												tooltip: {
													sx: {
														backgroundColor: "var(--accent-100)",
														color: "black",
														borderRadius: ".2rem",
														fontSize: ".8rem",
													},
												},
											}}
										>
											<FileTitle>
												<ul>
													{isSmallScreen ? (
														<li>{item.title}</li>
													) : (
														<li>{characters}</li>
													)}
												</ul>
											</FileTitle>
										</Tooltip>
									</div>
								);
							})}
					</FileContainers>
				</First>
			</>
		);
	} catch (error) {
		console.error("Error rendering video content: ", error);
		setVideoError("Error rendering video content");
	}
};

export default Video;

const FileContainers = styled.div`
	height: 24rem;
	overflow-y: scroll;
	margin-bottom: 1rem;
	margin-top: 1rem;

	@media (max-width: ${theme.breakpoints.sm}) {
		height: 15rem;
	}

	@media screen and (max-width: 1100px) {
		width: 22rem;
	}

	&::-webkit-scrollbar {
		width: 0.5em;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
	}

	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
`;

const First = styled.div`
	font-size: 0.9rem;

	.main {
		transition: background-color 0.3s ease, border-radius 0.3s ease;

		&:hover {
			background-color: lightgrey;
			color: var(--text-500);
		}

		margin-bottom: 1rem;
		border-radius: 0.5rem;
		display: flex;
		padding: 0.8rem;
		gap: 1rem;
		width: 18rem;
		overflow-x: hidden;

		.download {
			gap: 0.9rem;
			cursor: pointer;
			display: flex;

			.eye,
			.down,
			.pop,
			.tab {
				&:hover {
					color: var(--accent-100);
				}
			}
		}

		@media screen and (max-width: ${theme.breakpoints.xs}) {
			justify-content: flex-start;
		}

		@media screen and (max-width: 937px) {
			width: 15rem;
		}

		@media screen and (max-width: 1100px) {
			width: 14.5rem;
			gap: 0.7rem;
		}

		@media screen and (max-width: 1200px) {
			width: auto;
			gap: 0.7rem;
		}
	}

	@media screen and (max-width: 937px) {
		font-size: 0.8rem;
	}

	@media screen and (max-width: 1100px) {
		font-size: 0.8rem;
	}
`;

const FileTitle = styled.div`
	ul {
		width: 20rem;
	}

	li {
		list-style: none;
	}
`;
