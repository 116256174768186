export const data = {
	type: "buttons",
	response:
		"any text that makes sense - gets displayed prior to the button groups",
	button_groups: [
		{
			title:
				"title of the button group - gets displayed above the clickable buttons",
			buttons: [
				{
					text: "whatever is supposed to display to the user",
					prompt:
						"whatever the server wants sent to it when the button is clicked - if not present, send the display text",
				},
				{
					download: {
						text: "whatever the user sees that is clickable start the download workflow",
						url: "the link to the file to download",
					},
				},
			],
		},
		{
			title: "citations",
			buttons: [
				{
					text: "Drug document 1",
				},
				{
					download: {
						text: "Drug document 2",
						url: "http://signedUrlToFile.pdf",
					},
				},
			],
		},
		{
			title: "Followup Suggestions",
			buttons: [
				{
					text: "What type of accrual policy does my plan have for PTO?",
				},
				{
					text: "How many vacation days do I get?",
				},
			],
		},
	],
};
