import React, { useState } from "react";
import styled from "styled-components";
import { theme } from "../Theme";
import agent from "../../assets/AI.png";
import Logo from "../../assets/Logo.png";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useSearchParams } from "react-router-dom";

const ForgotPass = () => {
	const [isClicked, setIsClicked] = useState(false);
	const [username, setUsername] = useState("");
	const [error, setError] = useState(null);
	const [searchParams] = useSearchParams();
	const loc = searchParams.get("loc");
	const navigate = useNavigate();

	const handlePassword = async (e) => {
		try {
			await window.sdkInstance.forgotPassword(username, loc);
			setIsClicked(true);
		} catch (err) {
			console.error(err);
			setError(err);
		}
	};

	return (
		<>
			<HeaderContainer>
				<img src={agent} alt="benefits buddy agent logo" />
				<h3>Benefits Buddy</h3>
			</HeaderContainer>
			<FormContainer>
				<H1>Forgot Password</H1>
				<Instructions>
					Enter the username associated with your account and follow the
					instructions in the email to reset your password.
				</Instructions>
				<InputContainer>
					<Input
						placeholder="Username"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
				</InputContainer>
				{error ? (
					<p>{error.message}</p>
				) : isClicked ? (
					<>
						<Confirmation>
							An email has been sent to you. If you don't receive an email,
							please check your spam folder.
						</Confirmation>
						<NavigateContainer>
							<IoIosArrowBack />
							<p onClick={() => navigate("/")}>Back to Login</p>
						</NavigateContainer>
					</>
				) : (
					<Button onClick={handlePassword}>Reset</Button>
				)}
			</FormContainer>
			<Footer>
				<img src={Logo} alt="company logo" />
			</Footer>
		</>
	);
};

export default ForgotPass;

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin: 1rem;

	img {
		border-radius: 0.5rem;
		height: 5vh;
		width: 5vh;
	}

	h3 {
		font-weight: 800;
		padding-left: 0.7rem;
	}

	@media screen and (max-width: ${theme.breakpoints.sm}) {
		img {
			height: 4vh;
			width: 4vh;
		}

		h3 {
			font-size: 0.8rem;
		}
	}
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 60vh;
`;

const H1 = styled.h1`
	font-weight: 800 !important;
	font-size: 2.3rem;

	@media screen and (max-width: ${theme.breakpoints.sm}) {
		font-size: 2rem;
	}
`;

const Instructions = styled.p`
	width: 25rem;
	text-align: center;
	color: var(--text-200);
	margin-bottom: 3rem;
	font-size: 0.9rem;
	line-height: 1.5;
	margin-top: 3rem;

	@media screen and (max-width: ${theme.breakpoints.sm}) {
		width: 20rem;
		font-size: 0.7rem;
	}
`;

const Confirmation = styled.p`
	width: 20rem;
	margin-top: 2rem;
	text-align: center;
	background-color: #02ffb3;
	padding: 0.8rem;
	border-radius: 0.5rem;
	font-size: 0.8rem;
`;

const NavigateContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.6rem;
	margin-top: 1rem;
	font-size: 0.8rem;

	&:hover {
		color: var(--text-300);
		cursor: pointer;
	}
`;

const InputContainer = styled.div`
	width: 30rem;
	height: 5vh;
	border-radius: 0.5rem;
	background-color: #d9d9d9;
	align-content: center;
	margin: 0 auto;
	display: flex;

	@media screen and (max-width: ${theme.breakpoints.sm}) {
		width: 20rem;
		font-size: 16px;
	}
`;

const Input = styled.input`
	background-color: #d9d9d9;
	border: none;
	text-align: center;
	flex: 1;
	padding: 0.5rem;
	border-radius: 0.5rem;
	font-size: 1rem;

	&:focus {
		border: none;
		outline: none;
	}
`;

const Button = styled.button`
	background-color: #02ffb3;
	width: 15rem;
	height: 5vh;
	border: none;
	border-radius: 0.5rem;
	margin-top: 2rem;
	font-size: 1.1rem;
	cursor: pointer;

	@media screen and (max-width: ${theme.breakpoints.sm}) {
		font-size: 1rem;
		width: 12rem;
		margin-top: 1rem;
	}
`;

const Footer = styled.footer`
	position: absolute;
	bottom: 0;
	width: 100%;
	text-align: center;

	img {
		height: 8rem;
	}

	@media screen and (max-width: ${theme.breakpoints.sm}) {
		img {
			height: 6rem;
		}
	}
`;
