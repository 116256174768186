import React, { useContext } from "react";
import { ChatboxContext } from "../ChatContext";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import styled from "styled-components";
import { FaArrowRightToBracket } from "react-icons/fa6";
import { theme } from "../../Theme";

const Clarification = ({ assistantContent }) => {
	const {
		setPrompt,
		setDisplayAvatar,
		submitButtonDisabled,
		setSubmitButtonDisabled,
	} = useContext(ChatboxContext);

	const handleButtonClick = (buttonText) => {
		setPrompt(buttonText);
		setSubmitButtonDisabled(!submitButtonDisabled);
	};

	// console.log(assistantContent);

	return (
		<div>
			<>
				{assistantContent &&
					assistantContent.button_groups.map((group, index) => (
						<div key={index}>
							<Header>
								<Menu />
								{group.title && <h4>{group.title}</h4>}
							</Header>
							<p>
								{group.stacked === true ? (
									<StackedContainer>
										{group.buttons.map((button, i) => (
											<StackedButtonContainer key={i}>
												<StackedButtons
													key={i}
													disabled={submitButtonDisabled}
													onClick={() => {
														if (group.displayAvatar === true) {
															setDisplayAvatar(true);
														}
														handleButtonClick(
															button.prompt ? button.prompt : button.text
														);
													}}
												>
													{button.text}
													<Arrow />
												</StackedButtons>
											</StackedButtonContainer>
										))}
									</StackedContainer>
								) : (
									<MainButtonContainer>
										{group.buttons.map((button, i) => (
											<ButtonContainer key={i}>
												<Buttons
													key={i}
													disabled={submitButtonDisabled}
													onClick={() =>
														handleButtonClick(
															button.prompt ? button.prompt : button.text
														)
													}
												>
													{button.text}
												</Buttons>
											</ButtonContainer>
										))}
									</MainButtonContainer>
								)}
							</p>
						</div>
					))}
			</>
		</div>
	);
};

export default Clarification;

const Header = styled.div`
	display: flex;
	align-items: center;
	margin: 1rem 0rem;
	padding-top: 0.1rem;
`;

const Menu = styled(AiOutlineMenuUnfold)`
	margin-right: 0.4rem;
`;

const MainButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	overflow-x: hidden;

	@media (max-width: ${theme.breakpoints.xs}),
		(max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.md}) {
		flex-direction: column;
	}
`;

const StackedContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
`;

const ButtonContainer = styled.div`
	margin: 0.43rem;
`;

const Buttons = styled.button`
	width: fit-content;
	padding: 0.5rem 0.8rem;
	border: solid var(--accent-100) 1px;
	background-color: transparent;
	color: var(--text-100);
	border-radius: 3rem;
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
	text-align: start;
	font-size: 0.8rem;
	transition: background-color 0.3s ease, border-radius 0.3s ease;

	&:hover {
		background-color: grey;
	}
`;

const StackedButtonContainer = styled.div`
	border-top: solid var(--text-200) 1px;
	width: 100%;
	transition: border-top 0.3s ease, border-radius 0.3s ease;

	&:hover {
		border-top: solid var(--accent-100) 1px;
		width: 100%;
	}
`;

const StackedButtons = styled.button`
	background-color: transparent;
	color: var(--text-100);
	padding: 0.9rem;
	text-align: start;
	line-height: 1.4;
	border: none;
	width: 100%;
	box-sizing: border-box;
	font-size: 0.8rem;
	transition: background-color 0.3s ease, border-radius 0.3s ease;
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	align-items: center;
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

	&:hover {
		background-color: grey;
		width: 100%;
	}
`;

const Arrow = styled(FaArrowRightToBracket)`
	font-size: 1rem;
	align-self: center;
`;
