import React, {
	useEffect,
	useState,
	createContext,
	useRef,
	useContext,
} from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { SDK } from "../sdk/sdk/sdk.js";
import Dashboard from "./dashboard/Dashboard.js";
import LandingPage from "./LandingPage/Landing.js";
import { StyleSheetManager } from "styled-components";
import ChatHistory from "../components/dashboard/history/ChatHistory.js";
import ThemeProvider from "./ThemeContext.js";
import { theme } from "./Theme.js";
import ForgotPass from "./LandingPage/ForgotPass.js";
import SourcesContainer from "./test/SourcesContainer.js";
import Settings from "./dashboard/settings/Settings.js";

export const AuthContext = createContext();

const App = () => {
	const [user, setUser] = useState([]);
	const navigate = useNavigate();
	let location = useLocation();
	const isMounted = useRef(true);

	const userData = useContext(AuthContext);

	useEffect(() => {
		const cleanup = () => {
			isMounted.current = false;
		};

		async function startSdk() {
			console.log(`location: ${JSON.stringify(window.location)}`);
			const queryParams = window.location;
			window.sdkInstance = new SDK({
				backendPath: "",
				queryParams: queryParams,
			});

			window.sdkInstance.registerSuccessCallback(
				["auth"],
				async function (_category, name, data) {
					switch (name) {
						case "login": {
							if (!userData || !userData.userData || true) {
								window.sdkInstance.config.authToken = data.authToken;
								window.sdkInstance.config.userData = data.userData;
								setUser(data);
								console.log(`login callback: ${JSON.stringify(data)}`);
								const response = window.sdkInstance.config;

								if (response) {
									const userInfo = localStorage.getItem("config");

									if (!userInfo || location.pathname === "/") {
										localStorage.setItem("config", JSON.stringify(response));
										setUser(data);
										navigate("/dashboard");
									}
								} else {
									console.log("Authentication failed");
								}
							}
							break;
						}
						default: {
							console.log(`Unhandled auth success callback: ${name}`);
							break;
						}
					}
				}
			);
			window.sdkInstance.registerErrorCallback(
				["auth"],
				async function (_category, name, data) {
					switch (name) {
						case "login": {
							window.sdkInstance.logout();
							navigate("/");
							console.log(`showInvalidLoginMessage: ${JSON.stringify(data)}`);
							break;
						}
						default: {
							window.sdkInstance.logout();
							navigate("/");
							console.log(`Unhandled auth error callback: ${name}`);
							break;
						}
					}
				}
			);

			window.sdkInstance.registerSuccessCallback(
				["agent"],
				async function (_category, name, data) {
					switch (name) {
						case "newConversationWithMessage":
							window.sdkInstance.agent.setActiveConversation(
								data.conversationId
							);
							break;
						default: {
							console.log(`Unhandled auth success callback: ${name}`);
							break;
						}
					}
				}
			);

			await window.sdkInstance.start();
		}

		startSdk();

		return cleanup;
	}, [location, location.pathname, navigate, userData]);

	return (
		<ThemeProvider theme={theme}>
			<AuthContext.Provider value={user}>
				<StyleSheetManager shouldForwardProp={(prop) => prop !== "isVisible"}>
					<Routes>
						<Route path="/" element={<LandingPage />} />
						<Route path="/password" element={<ForgotPass />} />
						<Route path="/dashboard" element={<Dashboard />} />
						<Route path="/settings" element={<Settings />} />
						<Route path="/history" element={<ChatHistory />} />
						<Route path="/test" element={<SourcesContainer />} />
					</Routes>
				</StyleSheetManager>
			</AuthContext.Provider>
		</ThemeProvider>
	);
};

export default App;
