import React, { useEffect, useRef, useContext } from "react";
import styled, { ThemeProvider } from "styled-components";
import { BsReverseLayoutSidebarInsetReverse } from "react-icons/bs";
import NavItems from "../NavItems";
import { theme } from "../../Theme";
import Suggestions from "../Suggestions";
import { motion } from "framer-motion";
import UserItems from "../UserItems";
import FileContainer from "./FileContainer";
import { ChatboxContext } from "../ChatContext";
import { useMediaQuery } from "react-responsive";

const Directory = () => {
	const suggestionsRef = useRef(null);
	const { isOpen, setIsOpen } = useContext(ChatboxContext);

	useEffect(() => {
		if (suggestionsRef.current) {
			suggestionsRef.current.scrollTop = suggestionsRef.current.scrollHeight;
		}
	}, []);

	const toggleBar = () => {
		setIsOpen(!isOpen);
	};

	const updateSidebarState = () => {
		if (window.innerWidth <= 1100) {
			setIsOpen(false);
		} else {
			setIsOpen(true);
		}
	};

	useEffect(() => {
		updateSidebarState();
		window.addEventListener("resize", updateSidebarState);

		return () => {
			window.removeEventListener("resize", updateSidebarState);
		};
	}, []);

	const sideVariants = {
		open: {
			width: "auto",
			transition: { duration: 0.7 },
		},
		closed: {
			width: "0rem",
			transition: { duration: 0.7 },
		},
	};

	const isSmallScreen = useMediaQuery({ maxWidth: 1100 });

	return (
		<ThemeProvider theme={theme}>
			{isSmallScreen && (
				<Main>
					<div className="head">
						<h2>Knowledge Base</h2>
					</div>
					<div className="file-container">
						<FileContainer />
					</div>
					<NavItem>
						<NavItems />
					</NavItem>
					<Nav>
						<UserItems />
					</Nav>
				</Main>
			)}
			<Div variants={sideVariants} animate={isOpen ? "open" : "closed"}>
				{isOpen && !isSmallScreen && (
					<>
						<Open onClick={toggleBar}>
							<BsReverseLayoutSidebarInsetReverse />
						</Open>
						<div className="head">
							<h2>Knowledge Base</h2>
						</div>
						<div className="file-container">
							<FileContainer />
						</div>
						<NavItem>
							<NavItems />
						</NavItem>
						<Nav>
							<UserItems />
						</Nav>
						<Suggestions suggestionsRef={suggestionsRef} isOpen={isOpen} />
					</>
				)}
				<Open onClick={toggleBar}>
					<BsReverseLayoutSidebarInsetReverse />
				</Open>
			</Div>
		</ThemeProvider>
	);
};

export default Directory;

const Div = styled(motion.div)`
	background-color: var(--bg-200);
	color: var(--text-100);
	padding: 2rem 2rem 0rem;
	position: relative;
	right: 0;

	.title {
		font-weight: 400;
		margin-bottom: 1rem;
	}

	@media (max-width: ${theme.breakpoints.xs}),
		(max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.md}),
		(max-width: ${theme.breakpoints.lg}),
		(max-width: 1100px) {
		background-color: var(--bg-300);
		padding: 0rem;

		.title {
			align-self: flex-start;
		}
	}

	&::-webkit-scrollbar {
		width: 0.5em;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
	}

	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
`;

const Nav = styled.div`
	display: none;

	@media (max-width: ${theme.breakpoints.lg}), (max-width: 1100px) {
		display: flex;
		justify-content: space-between;
		margin-right: 0.5rem;
	}
`;

const NavItem = styled.div`
	display: none;

	@media (max-width: ${theme.breakpoints.lg}), (max-width: 1100px) {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		margin-right: 0.5rem;
		border-top: solid var(--text-100) 1px;
		padding-bottom: 0.7rem;
		gap: 9rem;
	}
`;

const Main = styled.div`
	color: var(--text-100);
	white-space: nowrap;
	overflow: auto;
	width: ${(props) => (props.isOpen ? "0vh" : "25rem")};
	flex: 1 1 auto;
	flex-wrap: wrap;
	transition: 0.2s cubic-bezier(0.36, -0.01, 0, 0.77);
	margin: 2rem 1rem;

	.head {
		display: flex;
		align-items: center;
	}

	@media (max-width: ${theme.breakpoints.xs}),
		(max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.md}),
		(max-width: ${theme.breakpoints.lg}),
		(max-width: 1100px) {
		width: auto;
		height: 80vh;
		padding: 0.5rem 1rem;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.head {
			padding-bottom: 1.5rem;
			justify-content: center;
			font-size: 0.9rem;
		}

		.file-container {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.files {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`;

const Open = styled.button`
	border: none;
	background-color: transparent;
	outline: none;
	border-radius: 0.5rem;
	width: 0;
	padding: 0.9rem;
	color: var(--text-100);
	cursor: pointer;
	font-size: 0.9rem;
	position: absolute;
	top: 1.5rem;
	right: 0.4rem;

	&:hover {
		transition-duration: 0.2s;
	}

	@media (max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.md}) {
		position: relative;
		top: 1rem;
		margin-top: 1rem;
	}

	@media (max-width: 1100px) {
		display: none;
	}
`;
