import React, { useState } from "react";
import Sources from "./Sources";
import styled from "styled-components";
import AI from "../../assets/AI.png";
import userImage from "../../assets/user.png";
import { LuCopy } from "react-icons/lu";
import { LuThumbsDown } from "react-icons/lu";

const SourcesContainer = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const today = new Date();
	const dateOptions = {
		month: "short",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		seconds: "numeric",
	};

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	return (
		<Main>
			<MessagesContainer>
				<InnerContainer>
					<Greeting>
						<Messages>
							<div className="cont">
								<AssistantImage src={AI} alt="assistant" />
								<p className="date">
									{today.toLocaleString("en-US", dateOptions)}
								</p>
							</div>
							<p className="welcome">
								Hi, there! I'm your dedicated Benefits Buddy at ABC. If you're
								curious about the benefits we offer or have any specific
								questions, I'm here to guide you through them. Don't hesitate to
								reach out for assistance with anything from understanding your
								health plan to details about our company's leave policies.
							</p>
						</Messages>
					</Greeting>
					<UserMessageContainer>
						<UserMessage>
							<div>
								<Timestamp>
									{today.toLocaleString("en-US", dateOptions)}
								</Timestamp>
								<GradientImage src={userImage} alt="user profile" />
							</div>
							<p>Do we have a high deductible plan?</p>
						</UserMessage>
					</UserMessageContainer>
					<AgentMessage>
						<AssistantImage src={AI} alt="assistant" />
						<Timestamp>{today.toLocaleString("en-US", dateOptions)}</Timestamp>
						<div>
							<p>
								Yes, ABC Company offers a High Deductible Health Plan (HDHP)
								with a Health Savings Account (HSA). Here are some key details
								about the HDHP: 1. Premiums: You'll pay less in premiums
								compared to other plans. 2. Deductibles: You will need to pay
								the full cost of non•preventive medical services until you reach
								your deductible. 3. Health Savings Account (HSA): You can use an
								HSA in conjunction with this plan, which provides a safety net
								for unexpected medical costs and offers tax advantages. 4.
								Preventive Care: Preventive care is covered at no charge.
								In•Network Benefits: • Individual Deductible: $1,600 • Family
								Deductible: $3,200 • Coinsurance: 80% after deductible •
								Out•of•Pocket Maximum (Individual): $4,000 • Out•of•Pocket
								Maximum (Family): $7,350 • Preventive Care: No charge • Primary
								Care: 20% after deductible • Specialist Services: 20% after
								deductible • Urgent Care: 20% after deductible • Emergency Room:
								20% after deductible Out•of•Network Benefits: • Individual
								Deductible: $3,200 • Family Deductible: $6,400 • Coinsurance:
								50% after deductible • Out•of•Pocket Maximum (Individual):
								$8,000 • Out•of•Pocket Maximum (Family): $16,000 • Preventive
								Care: 50% after deductible • Primary Care: 50% after deductible
								• Specialist Services: 50% after deductible • Urgent Care: 50%
								after deductible • Emergency Room: 50% after deductible If you
								have any more questions or need further assistance, feel free to
								ask!
							</p>
							<IconContainer>
								<Icons>
									<LuCopy />
								</Icons>
								<Icons>
									<LuThumbsDown />
								</Icons>
								<button onClick={toggleModal}>Sources</button>
							</IconContainer>
						</div>
					</AgentMessage>
					{isModalOpen && (
						<ModalContent>
							<Sources />
							<button onClick={toggleModal} className="close-button">
								X
							</button>
						</ModalContent>
					)}
				</InnerContainer>
			</MessagesContainer>
		</Main>
	);
};

export default SourcesContainer;

const ModalContent = styled.div`
	background: black;
	padding: 2rem;
	border-radius: 0.5rem;
	width: 48rem;
	border: solid white 1px;

	.close-button {
		background-color: white;
		color: green;
		border: none;
		padding: 0.5rem;
		border-radius: 0.3rem;
		cursor: pointer;
		margin-top: 1rem;
		position: relative;
		bottom: 20rem;
		left: 47rem;
	}
`;

const Main = styled.div`
	height: 100vh;
	width: 100vw;
	background-color: black;
	color: #f5ece5;
`;

const MessagesContainer = styled.div`
	overflow-y: scroll;
	background-color: var(--bg-300);
	border-radius: 0.3rem;
	scroll-snap-type: y mandatory;

	&::-webkit-scrollbar {
		width: 0.5em;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
	}

	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
`;

const InnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	background-color: black;
	width: 90vw;
	height: 80vw;
	overflow-y: scroll;
`;

const Greeting = styled.div`
	color: var(--text-100);
	margin-top: 2rem;

	img {
		height: 3rem;
		width: 3rem;
		border-radius: 0.4rem;
	}
`;

const Messages = styled.div`
	padding-left: 1rem;
	margin-bottom: 1rem;
	border-radius: 0.5rem;

	.cont {
		display: flex;
	}

	.date {
		font-size: 0.7rem;
		line-height: 1.2;
		align-self: flex-end;
		color: var(--text-200);
	}

	.welcome {
		font-size: 0.9rem;
		line-height: 1.5;
		margin-top: 0.9rem;
	}
`;

const AssistantImage = styled.img`
	height: 3rem;
	width: 3rem;
	border-radius: 0.4rem;
	margin-right: 1rem;
`;

const AgentMessage = styled.div`
	color: var(--text-100);
	padding: 1rem;
	margin-bottom: 1rem;
	border-radius: 0.5rem;
	animation: ${(props) =>
		props.isLastAssistant ? "slideUp 0.9s ease 0s 1 normal forwards" : ""};

	p {
		font-size: 0.9rem;
		line-height: 1.5;
		margin: 1rem 0 0.9rem 0;
	}

	img {
		height: 5vh;
		width: 5vh;
	}

	.error {
		color: var(--text-600);
		background-color: var(--bg-500);
		padding: 0.6rem;
		border-radius: 0.4rem;
		margin-top: 0.7rem;
		font-size: 1rem;
	}

	@keyframes slideUp {
		0% {
			opacity: 0;
			transform: translateY(50px);
		}

		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
`;

const UserMessage = styled.div`
	color: var(--text-200);
	padding: 1rem;
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	animation: slideUp 0.9s ease 0s 1 normal forwards;

	p {
		font-size: 0.9rem;
		line-height: 1.2;
		margin-top: 0.9rem;
		text-align: right;
	}

	@keyframes slideUp {
		0% {
			opacity: 0;
			transform: translateY(50px);
		}

		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
`;

const UserMessageContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 2rem;
`;

const GradientImage = styled.img`
	height: 4vh;
	width: 4vh;
	border-radius: 0.4rem;
`;

const Timestamp = styled.span`
	font-size: 0.7rem;
	color: var(--text-200);
	margin-bottom: 0.9rem;
	padding-right: 1rem;
`;

const Icons = styled.div`
	font-size: 1.1rem;
	color: var(--text-200);
	cursor: pointer;
	outline: none;

	&:focus {
		outline: none;
	}

	span {
		cursor: pointer;

		&:focus {
			outline: none;
		}

		&:active {
			outline: none;
		}
	}
`;

const IconContainer = styled.div`
	display: flex;
	gap: 1rem;
	/* border: solid red 1px; */

	button {
		background-color: black;
		color: white;
		border: solid white 1px;
		padding: 0.2rem 0.8rem;
		border-radius: 0.2rem;
		position: relative;
		right: -45rem;
		/* left: 50rem; */
	}
`;

const SourceContainer = styled.div``;
