import React from "react";
import styled from "styled-components";
import { IoSunnyOutline } from "react-icons/io5";
import { IoIosMoon } from "react-icons/io";

const Checkbox = ({ checked, onChange, currentTheme }) => (
	<CheckboxContainer>
		<StyledCheckbox checked={checked}>
			<IconContainer>
				<div>
					<HiddenCheckbox
						type="checkbox"
						checked={checked}
						onChange={onChange}
					/>
					<SunIcon currentTheme={currentTheme} checked={checked} />
				</div>
				<div>
					<HiddenCheckbox
						type="checkbox"
						checked={checked}
						onChange={onChange}
					/>
					<MoonIcon currentTheme={currentTheme} checked={checked} />
				</div>
			</IconContainer>
		</StyledCheckbox>
	</CheckboxContainer>
);

export default Checkbox;

const iconStyles = `
	border-radius: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 5px;
	padding: 0.3rem;
	font-size: 1.9rem;
`;

const IconContainer = styled.div`
	background-color: var(--bg-300);
	padding: 0.6rem;
	border-radius: 3rem;
	display: flex;
	margin-left: 1rem;
`;

const MoonIcon = styled(IoIosMoon)`
	${iconStyles}
	background-color: ${(props) =>
		props.currentTheme === "dark" ? "var(--accent-100)" : "transparent"};
	color: ${(props) =>
		props.currentTheme === "dark" ? "black" : "var(--text-100)"};
	transition: background-color 0.3s ease, color 0.3s ease;
`;

const SunIcon = styled(IoSunnyOutline)`
	${iconStyles}
	background-color: ${(props) =>
		props.currentTheme === "light" ? "var(--accent-100)" : "transparent"};
	color: ${(props) =>
		props.currentTheme === "light" ? "black" : "var(--text-100)"};
	transition: background-color 0.3s ease, color 0.3s ease;
`;

const CheckboxContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const StyledCheckbox = styled.div``;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
	position: absolute;
	height: 1rem;
	width: 2rem;
	opacity: 0;
	cursor: pointer;
`;
