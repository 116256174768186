import React, { useContext } from "react";
import styled from "styled-components";
import { ChatboxContext } from "../ChatContext";
import { LuCircleDashed } from "react-icons/lu";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";

const VideoPreferences = () => {
	const { selectedOption, setSelectedOption } =
		useContext(ChatboxContext);

	const handleOptionChange = (e) => {
		const newOption = e.target.value;
		setSelectedOption(newOption);
		localStorage.setItem("videoOption", newOption);
	};

	return (
		<OptionContainer>
			<Label htmlFor="viewInline">
				<HiddenRadio
					type="radio"
					id="viewInline"
					name="viewPreference"
					value="viewInline"
					checked={selectedOption === "viewInline"}
					onChange={handleOptionChange}
				/>
				<IconContainer>
					{selectedOption === "viewInline" ? <CheckMark /> : <Circle />}
				</IconContainer>
				View In Chat
			</Label>
			<Label htmlFor="viewPopup">
				<HiddenRadio
					type="radio"
					id="viewPopup"
					name="viewPreference"
					value="viewPopup"
					checked={selectedOption === "viewPopup"}
					onChange={handleOptionChange}
				/>
				<IconContainer>
					{selectedOption === "viewPopup" ? <CheckMark /> : <Circle />}
				</IconContainer>
				Open In Popup
			</Label>
			<Label htmlFor="viewTab">
				<HiddenRadio
					type="radio"
					id="viewTab"
					name="viewPreference"
					value="viewTab"
					checked={selectedOption === "viewTab"}
					onChange={handleOptionChange}
				/>
				<IconContainer>
					{selectedOption === "viewTab" ? <CheckMark /> : <Circle />}
				</IconContainer>
				Open In New Tab
			</Label>
		</OptionContainer>
	);
};

export default VideoPreferences;

const OptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: fit-content;
	line-height: 2;
	border: solid darkgrey 1px;
	padding: 1rem;
	border-radius: 0.3rem;
`;

const HiddenRadio = styled.input`
	display: none;
	align-items: center;
`;

const Label = styled.label`
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 1rem;
	color: var(--text-100);
	margin-bottom: 10px;
`;

const IconContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	margin-right: 8px;
`;

const Circle = styled(LuCircleDashed)`
	margin-right: 0.8rem;
	font-size: 1.3rem;
`;

const CheckMark = styled(IoIosCheckmarkCircleOutline)`
	color: var(--accent-100);
	margin-right: 0.8rem;
	font-size: 1.3rem;
`;
