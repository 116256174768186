import { createGlobalStyle } from "styled-components";
import { dashboardDarkTheme, dashboardLightTheme } from "./Theme";

const GlobalStyle = createGlobalStyle`

:root {
    --accent-100: #02ffb3;
	--text-100: #f4f3ee;
	--text-200: #8a817c;
	--text-300: #BB73CE;
	--text-400: -#0a100d;
	--text-500: #001219;
	--text-600: #E02424;
	--bg-100: #fdfffc;
	--bg-200: #0a100d;
	--bg-300: #333;
	--bg-400: #333533;
	--bg-500: #FFF2F2;
}


[data-theme='light-dashboard']{
	--accent-100: ${dashboardLightTheme["--accent-100"]};
	--text-100: ${dashboardLightTheme["--text-100"]};
	--text-200: ${dashboardLightTheme["--text-200"]};
	--text-300: ${dashboardLightTheme["--text-300"]};
	--text-400: ${dashboardLightTheme["--text-400"]};
	--text-500: ${dashboardLightTheme["--text-500"]};
	--text-600: ${dashboardLightTheme["--text-600"]};
	--bg-100: ${dashboardLightTheme["--bg-100"]};
	--bg-200: ${dashboardLightTheme["--bg-200"]};
	--bg-300: ${dashboardLightTheme["--bg-300"]};
	--bg-400: ${dashboardLightTheme["--bg-400"]};
	--bg-500: ${dashboardLightTheme["--bg-500"]};
}

[data-theme='dark-dashboard']{
	--accent-100: ${dashboardDarkTheme["--accent-100"]};
	--text-100: ${dashboardDarkTheme["--text-100"]};
	--text-200: ${dashboardDarkTheme["--text-200"]};
	--text-300: ${dashboardDarkTheme["--text-300"]};
	--text-400: ${dashboardDarkTheme["--text-400"]};
	--text-500: ${dashboardDarkTheme["--text-500"]};
	--text-600: ${dashboardDarkTheme["--text-600"]};
	--bg-100: ${dashboardDarkTheme["--bg-100"]};
	--bg-200: ${dashboardDarkTheme["--bg-200"]};
	--bg-300: ${dashboardDarkTheme["--bg-300"]};
	--bg-400: ${dashboardDarkTheme["--bg-400"]};
	--bg-500: ${dashboardDarkTheme["--bg-500"]};
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
 height: 100vh;
 max-width: 100vw;
 margin: 0;
font-family: "Inter", sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

`;

export default GlobalStyle;
