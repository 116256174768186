import React, { useRef, useState } from "react";
import styled from "styled-components";
import FormModal from "./FormModal";
import { theme } from "../../Theme";
import { IoIosCloseCircleOutline } from "react-icons/io";

const BadResponseForm = ({ combinedText, closeForm }) => {
	const [feedbackResponse, setFeedbackResponse] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const badResponseFormRef = useRef(null);

	const assistantMessage = combinedText.find(
		(message) => message.type === "assistant"
	);

	const handleBadResponse = async (feedbackContent) => {
		try {
			await window.sdkInstance.agent.addMessageFeedback(
				assistantMessage.id,
				feedbackContent
			);

			setFeedbackResponse(feedbackContent);
			const updatedCombinedText = combinedText.map((message) =>
				message.id === assistantMessage.id
					? { ...message, feedback: feedbackContent }
					: message
			);

			setIsModalOpen(false);
			setTimeout(() => {
				setShowMessage(true);
				setTimeout(() => {
					setShowMessage(false);
					closeForm();
				}, 4000);
			}, 100);

			console.log("Feedback submitted:", updatedCombinedText);
		} catch (err) {
			console.error("Error submitting feedback:", err);
		}
	};

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<Main ref={badResponseFormRef}>
			<MainContent>
				{showMessage && (
					<ThankYouMessage>
						Thank you! We appreciate your feedback
					</ThankYouMessage>
				)}
				<Container>
					<Close onClick={() => closeForm()}>
						<IoIosCloseCircleOutline />
					</Close>
					<h5>Tell us more:</h5>
					<ButtonContainer>
						<Button
							onClick={() => {
								handleBadResponse("Not actually correct");
							}}
						>
							Not actually correct
						</Button>
						<Button
							onClick={() => {
								handleBadResponse("Didn't follow instructions");
							}}
						>
							Didn't follow instructions
						</Button>
						<Button
							onClick={() => {
								handleBadResponse("Didn't give me the information I needed");
							}}
						>
							Didn't give me the information I needed
						</Button>
					</ButtonContainer>
					<Button onClick={openModal}>More..</Button>
				</Container>
				{isModalOpen && (
					<FormModal
						isOpen={isModalOpen}
						closeModal={closeModal}
						handleBadResponse={handleBadResponse}
						feedbackResponse={feedbackResponse}
					/>
				)}
			</MainContent>
		</Main>
	);
};

export default BadResponseForm;

const Main = styled.div`
	/* height: auto;
	animation: myAnim 1s ease 0s 1 normal forwards;
	width: 100%;
	position: relative;

	@keyframes myAnim {
		0% {
			transform: translateY(-10px);
		}

		100% {
			transform: translateY(0);
		}
	} */
`;

const MainContent = styled.div`
	height: auto;
	animation: myAnim 1s ease 0s 1 normal forwards;
	/* width: 60vw; */
	position: relative;

	@keyframes myAnim {
		0% {
			transform: translateY(-10px);
		}

		100% {
			transform: translateY(0);
		}
	}
`;

const Container = styled.div`
	background-color: #4ff9c6cc;
	border-radius: 0.5rem;
	padding: 1rem;
	margin-bottom: 2rem;
	color: var(--text-100);

	h5 {
		width: fit-content;
		color: var(--text-500);
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		margin-bottom: 1rem;
	}
`;

const Button = styled.button`
	cursor: pointer;
	border: none;
	padding: 0.8rem;
	border-radius: 0.4rem;
	margin: 0.4rem;
	background-color: var(--bg-200);
	font-size: 0.8rem;
	color: var(--text-100);
	text-align: left;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: var(--bg-300);
	}

	@media (max-width: ${theme.breakpoints.sm}) {
		padding: 0.7rem;
	}
`;

const ButtonContainer = styled.div`
	display: flex;

	@media (max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.md}) {
		flex-direction: column;
	}
`;

const ThankYouMessage = styled.div`
	color: var(--text-100);
	font-size: 0.8rem;
	background-color: transparent;
	border: solid var(--text-100) 0.5px;
	border-radius: 0.5rem;
	padding: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	text-align: center;
	margin-bottom: 0.5rem;

	@media (max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.xs}),
		(max-width: ${theme.breakpoints.md}) {
		font-size: 0.7rem;
		padding: 0.6rem;
	}
`;

const Close = styled.div`
	cursor: pointer;
	float: right;
	font-size: 1.2rem;
	color: var(--text-500);
	z-index: 100;
`;
