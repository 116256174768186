import React, { useContext, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { theme } from "../Theme";
import { AuthContext } from "../App";
import { IoSettingsOutline } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { FiLogOut } from "react-icons/fi";
import { useMediaQuery } from "react-responsive";

const UserItems = () => {
	const [loggingOut, setLoggingOut] = useState(false);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const navigate = useNavigate();
	const user = useContext(AuthContext);

	const openDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};

	const handleLogout = async () => {
		try {
			setLoggingOut(true);
			await window.sdkInstance.logout();
			localStorage.removeItem("config");
			sessionStorage.removeItem("loadingCompleted");
			navigate("/");
		} catch (error) {
			console.error("Error during logout:", error);
		} finally {
			setLoggingOut(false);
		}
	};

	const isSmallScreen = useMediaQuery({ maxWidth: 1100 });

	return (
		<NavMenu>
			<NavItem onClick={openDropdown}>
				<p>{user.userData && user.userData.username}</p>
			</NavItem>
			<NavItem>
				<DropdownToggle onClick={openDropdown}>
					{dropdownOpen ? (
						<>
							<IoIosArrowUp className="arrow" />
							<DropdownContainer>
								<DropdownItem onClick={handleLogout} disabled={loggingOut}>
									Logout <FiLogOut />
								</DropdownItem>
								<DropdownItem onClick={() => navigate("/settings")}>
									Settings <Settings />
								</DropdownItem>
							</DropdownContainer>
						</>
					) : (
						<IoIosArrowDown className="arrow" />
					)}
				</DropdownToggle>
			</NavItem>
		</NavMenu>
	);
};

export default UserItems;

const NavMenu = styled.div`
	display: flex;
	padding-right: 1rem;

	@media (max-width: ${theme.breakpoints.lg}),
		(max-width: 1100px),
		(max-width: 1000px) {
		flex-direction: row;
		align-items: flex-end;
		gap: 10rem;
	}

	@media (max-width: ${theme.breakpoints.xs}),
		(max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.md}) {
		flex-direction: row;
		align-items: flex-end;
		gap: 10rem;
	}
`;

const Settings = styled(IoSettingsOutline)`
	cursor: pointer;
	font-size: 1.2rem;
`;

const DropdownToggle = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	position: absolute;

	.arrow {
		cursor: pointer;

		&:hover {
			color: var(--text-100);
		}
	}

	@media (max-width: ${theme.breakpoints.lg}),
		(max-width: 1100px),
		(max-width: 1000px) {
		position: relative;
		right: 8rem;
	}
`;

const DropdownContainer = styled.div`
	position: relative;
	top: 3.4rem;
	right: 11rem;
	background-color: var(--bg-200);
	z-index: 1000;
	border-radius: 0.3rem;
	color: var(--text-100);
	animation: myAnim 1s ease 0s 1 normal forwards;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	width: 11rem;

	@keyframes myAnim {
		0% {
			opacity: 0;
			transform: translateY(-10px);
		}

		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	@media (max-width: ${theme.breakpoints.lg}),
		(max-width: 1100px),
		(max-width: 1000px) {
		position: absolute;
		left: 0;
		top: 0rem;
		left: 2rem;
	}
`;

const DropdownItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5rem 1rem;
	cursor: pointer;
	transition: background-color 0.3s ease;
	border-radius: 0.3rem;
	width: 100%;
	box-sizing: border-box;

	&:hover {
		background-color: var(--accent-100);
		color: var(--text-500);
		border-radius: 0.3rem;
	}

	&:disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}
`;

const NavItem = styled.div`
	padding: 0.5rem 0.1rem;
	display: flex;
	align-items: center;
	font-size: 0.9rem;

	p {
		cursor: pointer;
	}

	@media (max-width: ${theme.breakpoints.lg}),
		(max-width: 1100px),
		(max-width: 1000px) {
		padding: 0.5rem 0rem;
	}
`;
