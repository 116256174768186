import React, { useState, useContext, createContext } from "react";
const ChatboxContext = createContext();

const ChatContext = ({ children }) => {
	const [isLoading, setIsloading] = useState(false);
	const [messages, setMessages] = useState("");
	const [combinedText, setCombinedText] = useState([]);
	const [assistantChat, setAssistantChat] = useState([]);
	const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
	const [error, setError] = useState(false);
	const [list, setList] = useState([]);
	const [visiblePacifier, setVisiblePacifier] = useState(null);
	const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);
	const [isListSet, setIsListSet] = useState([]);
	const [isTyping, setIsTyping] = useState(false);
	const [video, setVideo] = useState(null);
	const [prompt, setPrompt] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(true);
	const [sources, setSources] = useState("");
	const [videoError, setVideoError] = useState(null);
	const [displayAvatar, setDisplayAvatar] = useState(false);
	const [activeGroup, setActiveGroup] = useState(null);
	const [isOpen, setIsOpen] = useState(true);
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [isDownload, setIsDownload] = useState(null);
	const [isVisible, setIsVisible] = useState(false);
	const [filesList, setFilesList] = useState([]);
	const [downloadStatus, setDownloadStatus] = useState(null);
	const [showMenu, setShowMenu] = useState(false);
	const [selectedOption, setSelectedOption] = useState("viewInline");
	const [checkboxes, setCheckboxes] = useState({
		switchToLightMode: false,
	});

	const chatContextValues = {
		isLoading,
		setIsloading,
		messages,
		setMessages,
		combinedText,
		setCombinedText,
		assistantChat,
		setAssistantChat,
		submitButtonDisabled,
		setSubmitButtonDisabled,
		error,
		setError,
		list,
		setList,
		visiblePacifier,
		setVisiblePacifier,
		isSuggestionsVisible,
		setIsSuggestionsVisible,
		isListSet,
		setIsListSet,
		isTyping,
		setIsTyping,
		video,
		setVideo,
		prompt,
		setPrompt,
		isModalOpen,
		setIsModalOpen,
		sources,
		setSources,
		videoError,
		setVideoError,
		displayAvatar,
		setDisplayAvatar,
		activeGroup,
		setActiveGroup,
		isOpen,
		setIsOpen,
		isPopupOpen,
		setIsPopupOpen,
		isDownload,
		setIsDownload,
		isVisible,
		setIsVisible,
		filesList,
		setFilesList,
		downloadStatus,
		setDownloadStatus,
		checkboxes,
		setCheckboxes,
		showMenu,
		setShowMenu,
		selectedOption,
		setSelectedOption,
	};

	return (
		<ChatboxContext.Provider value={chatContextValues}>
			{children}
		</ChatboxContext.Provider>
	);
};

const useChatContext = () => useContext(ChatContext);

export { useChatContext, ChatboxContext, ChatContext };
