import React from "react";
import styled from "styled-components";
import AI from "../../../assets/AI.png";
import { BiLeftArrowAlt } from "react-icons/bi";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import userImage from "../../../assets/user.png";
import { theme } from "../../Theme";
import Sources from "../buttons/Sources";
import Feedback from "../chat/Feedback";
import Clarification from "../buttons/Clarification";

const parseContent = (content) => {
	let cleanedContent = content
		.replace(/\*\*/g, "")
		.replace(/^-/gm, "• ")
		.replace(/^ {2}-/gm, "• ");

	const lines = cleanedContent.split("\n");
	return lines.map((line, index) => {
		if (line.startsWith("### ")) {
			return (
				<p
					key={index}
					style={{
						fontWeight: "bolder",
						marginTop: "2rem",
						fontSize: "1.2rem",
					}}
				>
					{line.slice(4)}
				</p>
			);
		} else if (line.startsWith("#### ")) {
			return (
				<p
					key={index}
					style={{
						fontWeight: "bolder",
						marginTop: "2rem",
						fontSize: "1.2rem",
					}}
				>
					{line.slice(5)}
				</p>
			);
		} else if (line.startsWith("• ")) {
			return (
				<div
					style={{
						textIndent: "0.3rem",
					}}
				>
					<p
						key={index}
						style={{
							marginLeft: "1rem",
						}}
					>
						{line}
					</p>
				</div>
			);
		} else {
			return <p key={index}>{line}</p>;
		}
	});
};

const ChatHistoryId = ({ conversation, goBack }) => {
	let myUserPrompt;

	return (
		<Container>
			<MessagesContainer>
				<Link to="#" onClick={goBack} className="link">
					<BiLeftArrowAlt />
				</Link>
				{conversation.messages.map((message, i) => {
					const isUser = message.type === "user";
					if (isUser) {
						myUserPrompt = message.content;
					}
					const isAssistant = message.type === "assistant";
					const formattedTime = dayjs(message.created_at).format(
						"h:mm:ss A, D MMM"
					);
					let assistantContent;
					let cleanedContent;

					if (message && typeof message.content === "string") {
						try {
							assistantContent = JSON.parse(message.content);
							if (assistantContent && assistantContent.response) {
								cleanedContent = parseContent(assistantContent.response);
							}
						} catch (error) {
							cleanedContent = parseContent(message.content);
						}
					}

					return (
						<div key={i}>
							{isUser && (
								<UserMessageContainer>
									<UserMessage>
										{message?.content !== "Welcome" &&
										!message?.content.startsWith("//") ? (
											<>
												<div>
													<Timestamp>{formattedTime}</Timestamp>
													<GradientImage src={userImage} alt="user profile" />
												</div>
												<div>
													<p>{message?.content}</p>
												</div>
											</>
										) : null}
									</UserMessage>
								</UserMessageContainer>
							)}
							{isAssistant && (
								<AgentMessage>
									<div className="info">
										{myUserPrompt !== "Welcome" &&
										!myUserPrompt.startsWith("//") ? (
											<>
												<AssistantImage src={AI} alt="assistant" />
												<Timestamp>{formattedTime}</Timestamp>
											</>
										) : null}
									</div>
									<div>
										<p>{cleanedContent}</p>
										<BottomContainer>
											{assistantContent &&
												assistantContent.type === "buttons" &&
												(assistantContent.button_groups &&
												assistantContent.button_groups.some(
													(group) => group.title === "Sources"
												) ? (
													<>
														<Sources assistantContent={assistantContent} />
													</>
												) : (
													<>
														<Clarification
															assistantContent={assistantContent}
														/>
														<Feedback content={message.content} />
													</>
												))}
										</BottomContainer>
									</div>
								</AgentMessage>
							)}
						</div>
					);
				})}
			</MessagesContainer>
		</Container>
	);
};

export default ChatHistoryId;

const BottomContainer = styled.div`
`;

const Container = styled.div`
	width: 70vw;
	margin: 0 auto;
	overflow-y: scroll;
	background-color: var(--bg-200);
	height: 87vh;

	&::-webkit-scrollbar {
		width: 0.5em;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
	}

	scrollbar-width: thin;
	scrollbar-color: transparent transparent;

	@media (max-width: ${theme.breakpoints.sm}) {
		height: 70vh;
	}
`;

const MessagesContainer = styled.div`
	margin-top: 20px;

	.link {
		font-size: 1.3rem;
		color: var(--text-200);

		&:hover {
			color: var(--accent-100);
		}
	}
`;

const Timestamp = styled.span`
	font-size: 0.7rem;
	color: var(--text-200);
	margin-bottom: 0.9rem;
	padding-right: 1rem;
`;

const UserMessage = styled.div`
	color: var(--text-200);
	padding: 1rem;
	margin-bottom: 1rem;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	p {
		font-size: 0.9rem;
		line-height: 1.2;
		margin-top: 0.5rem;
		padding: 1rem;
	}
`;

const UserMessageContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 2rem;
`;

const GradientImage = styled.img`
	height: 5vh;
	width: 5vh;
	border-radius: 0.4rem;
	position: relative;
	top: 1rem;
`;

const AgentMessage = styled.div`
	color: var(--text-100);
	padding: 1rem;
	margin-bottom: 1rem;
	border-radius: 0.5rem;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-right: auto;

	.info {
		display: flex;
		align-items: center;
		margin-bottom: 0.5rem;
	}

	.info img {
		height: 5vh;
		width: 5vh;
		border-radius: 0.4rem;
		margin-right: 1rem;
	}

	p {
		font-size: 0.9rem;
		line-height: 1.5;
		margin: 1rem 0 0.9rem 0;
	}
`;

const AssistantImage = styled.img`
	height: 4vh;
	width: 4vh;
	border-radius: 0.4rem;
`;
