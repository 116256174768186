import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { theme } from "../../Theme";

Modal.setAppElement("#root");

const FormModal = ({
	isOpen,
	closeModal,
	handleBadResponse,
	feedbackResponse,
}) => {
	const [localFeedbackResponse, setLocalFeedbackResponse] =
		useState(feedbackResponse);

	const handleChange = (e) => {
		setLocalFeedbackResponse(e.target.value);
	};

	const handleSubmit = () => {
		handleBadResponse(localFeedbackResponse);
	};

	return (
		<Main>
			<Modal
				isOpen={isOpen}
				onRequestClose={closeModal}
				className="Modal"
				style={{
					overlay: {
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					},
					content: {
						backgroundColor: "var(--bg-400)",
						color: "var(--text-100)",
						borderRadius: "1rem",
						border: "none",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						padding: ".2rem",
						margin: ".5rem",
						position: "relative",
						top: "0",
						left: "0",
						right: "0",
						boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
					},
				}}
			>
				<Container>
					<TextContainer>
						<Header>Provide additional feedback</Header>
						<p>
							You chose more. Please give us more information and feel free to
							add specific details. We will like to serve you better.
						</p>
					</TextContainer>
					<Textarea
						placeholder="Tell us what you noticed"
						value={localFeedbackResponse}
						onChange={handleChange}
						tabIndex={0}
						rows={0}
					/>
					<ButtonContainer>
						<button onClick={handleSubmit}>Submit</button>
					</ButtonContainer>
				</Container>
			</Modal>
		</Main>
	);
};

export default FormModal;

const Main = styled.div`
	.Modal {
		width: 600px;
		height: 380px;
		@media (max-width: ${theme.breakpoints.sm}) {
			width: 300px;
			height: 100px;
		}
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1rem;
	font-family: "Inter", sans-serif;
`;

const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	p {
		font-size: 0.9rem;
	}
`;

const Header = styled.h1`
	border-bottom: solid white 1px;
	padding-bottom: 1.5rem;
	margin-bottom: 1.5rem;
	width: 100%;

	@media (max-width: ${theme.breakpoints.sm}) {
		font-size: 1.3rem;
	}
`;

const Textarea = styled.textarea`
	background-color: transparent;
	border-radius: 0.6rem;
	border: solid #878484 1px;
	padding: 0.6rem;
	margin: 1rem 0rem;
	resize: none;
	font-family: "Inter", sans-serif;
	vertical-align: middle;
	height: 3rem;
	color: var(--text-100);
	outline: none;
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	margin-top: auto;

	button {
		background-color: var(--accent-100);
		padding: 0.6rem;
		border-radius: 0.6rem;
		border: none;
		color: var(--text-500);
	}
`;
