import React, { useEffect, useState, useContext } from "react";
import Directory from "./directory/Directory.js";
import Navbar from "./Navbar";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../App.js";
import { theme } from "../Theme.js";
import Lottie from "react-lottie";
import data from "../data.json";
import { ChatboxContext } from "./ChatContext";
import dayjs from "dayjs";
import ChatMessage from "./chat/ChatMessage.js";
import NavContainer from "./NavContainer.js";
import { useMediaQuery } from "react-responsive";

const Dashboard = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const user = useContext(AuthContext);
	const {
		setIsloading,
		messages,
		setMessages,
		combinedText,
		setCombinedText,
		setSubmitButtonDisabled,
		setError,
		video,
		setVideo,
		prompt,
		setSources,
		sources,
		setPrompt,
		list,
	} = useContext(ChatboxContext);

	useEffect(() => {
		if (!user) {
			navigate("/");
		} else {
			if (user.userData) {
				user.userData.disclaimer = true;
				if (!sessionStorage.getItem("loadingCompleted")) {
					const loadingTimeout = setTimeout(() => {
						setLoading(false);
						sessionStorage.setItem("loadingCompleted", "true");
					}, 4000);
					return () => clearTimeout(loadingTimeout);
				} else {
					setLoading(false);
				}
			}
		}
	}, [navigate, user, user.userData]);

	useEffect(() => {
		if (combinedText.length) {
			return;
		}

		if (list.pacifiers && list.pacifiers.length) {
			setPrompt("Welcome");
		}
	}, [combinedText.length, list.pacifiers, setPrompt]);

	const handleChat = async () => {
		try {
			const message = prompt;
			if (message === "") {
				return;
			}
			const msg = typeof message === "string" ? String(message) : messages;
			setSubmitButtonDisabled(true);
			const agent = window.sdkInstance.agent;

			setCombinedText((prevCombinedText) => [
				...prevCombinedText,
				{
					type: "user",
					content: msg,
					created_at: dayjs().format("h:mm:ss A, D MMM"),
				},
				{ type: "assistant", content: "", loading: true },
			]);

			if (!msg.trim()) {
				return;
			}

			setIsloading(true);
			if (!agent.getActiveConversation()) {
				setMessages("");
				await agent.newConversationWithMessage(msg);
			} else {
				await agent.addMessageToConversation(msg);
			}

			await getRunCompletion(msg);
		} finally {
			setSubmitButtonDisabled(false);
		}
	};

	useEffect(() => {
		handleChat();
	}, [prompt]);

	const getRunCompletion = async () => {
		try {
			for (let i = 0; i < 60; i++) {
				console.log(`getRunCompletion trying ${i} time`);
				const response = await window.sdkInstance.agent.getAgent();

				if (response && response.agent) {
					if (response.agent.run_status === "completed") {
						const convId = window.sdkInstance.agent.getActiveConversation();
						const messageResponse =
							await window.sdkInstance.agent.getConversation(convId);
						console.log("get conversation", messageResponse);
						const newMessages = messageResponse.messages;

						let lastMessage = newMessages[newMessages.length - 1];
						let prevMessage = newMessages[newMessages.length - 2];

						let content;
						if (content && content.type && content.type === "buttons") {
							setSources(content);
							console.log(sources);
						}
						if (
							prevMessage.content.startsWith("//media") ||
							lastMessage.content.includes(`media_request`) ||
							(lastMessage.content.type &&
								lastMessage.content.type == "media_request")
						) {
							try {
								content = JSON.parse(lastMessage.content);
							} catch (err) {
								console.log(`invalid media response content`);
							}

							console.log(`media content: ${JSON.stringify(content)}`);

							if (
								content &&
								content.type &&
								content.type == "media_request" &&
								content.status == "ok"
							) {
								if (content.url) {
									const screenWidth = window.screen.availWidth;
									const screenHeight = window.screen.availHeight;
									const windowWidth = 600; // Width of the new window
									const windowHeight = 500; // Height of the new window

									// Calculate the position of the upper right corner
									const leftPos = screenWidth - windowWidth - 200;
									//const leftPos = 0;
									const topPos = 0;

									let wname = "Benefits Buddy Media";
									let newWindow;
									if (content.cmd.includes("popup")) {
										setVideo(content);
										lastMessage.content = "media center begin";
										console.log("content here", content);
										console.log("video here", video);
									} else {
										if (!content.cmd.includes("tab")) {
											console.log("launch window");
											newWindow = window.open(
												content.url,
												"Benefits Buddy Media",
												`width=${windowWidth},height=${windowHeight},left=${leftPos},top=${topPos},alwaysRaised=yes`
											);
											await setFocus(newWindow);
										} else {
											console.log("launch tab");
											newWindow = window.open(
												content.url,
												"Benefits Buddy Media"
											);
											await setFocus(newWindow);
										}
										if (!newWindow) {
											console.log(`no window handle`);
											newWindow = window.open("", "Benefits Buddy Media");
											if (newWindow) console.log(`got one now`);
										}
										if (newWindow) {
											lastMessage.content = "media center begin";
										} else {
											lastMessage.content = "error launching media center";
											console.log(`error opening media center`);
										}
									}
								} else {
									if (content.cmd) {
										if (content.cmd == "stop") {
											const wind = window.open("", "Benefits Buddy Media");
											setVideo(null);
											if (wind) {
												wind.close();
												lastMessage.content = "media center stopped";
												setVideo(null);
												console.log(video);
											} else {
												lastMessage.content = "no active media center";
											}
										}
										if (content.cmd == "page") {
											if (!video) {
												const wind = window.open("", "Benefits Buddy Media");
												await setFocus(wind);
											}
											lastMessage.content = "page changed";
										}
										if (
											content.cmd == "window" ||
											content.cmd == "popup" ||
											content.cmd == "tabs"
										) {
											lastMessage.content = `preference changed: ${content.cmd}`;
										}
									} else {
										lastMessage.content = "invalid media center response";
									}
								}
							}
						}
						if (lastMessage.content != "media center begin") {
							if (newMessages && newMessages.length >= 2) {
								setCombinedText((prevCombinedText) => [
									...prevCombinedText.slice(0, -2),
									prevMessage,
									lastMessage,
								]);

								localStorage.setItem(
									"allMessages",
									JSON.stringify(combinedText)
								);
								setIsloading(false);
								setPrompt("");
								return true;
							}
						} else {
							setCombinedText((prevCombinedText) => [
								...prevCombinedText.slice(0, -2),
							]);

							localStorage.setItem("allMessages", JSON.stringify(combinedText));
							setIsloading(false);
							setPrompt("");
							return true;
						}
					}
				}
				await sleep(1000);
			}
			throw new Error("Exceeded maximum attempts (50) to get run completion.");
		} catch (error) {
			console.error("Error in getRunCompletion:", error);
			setError(
				"Network issues, need to refresh, prompt not related to benefits, etc."
			);
		}
	};

	const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
	async function setFocus(wind) {
		try {
			await sleep(1000);
			wind.focus();
		} catch (err) {}
	}
	const isSmallScreen = useMediaQuery({ maxWidth: 1100 });

	return (
		<>
			{loading && (
				<LoadingOverlay>
					<Lottie
						options={{
							animationData: data,
							rendererSettings: {
								preserveAspectRatio: "xMidYMid slice",
							},
						}}
						height={700}
						width={700}
					/>
				</LoadingOverlay>
			)}
			<Div>
				<Main>
					<Navbar />
					{!isSmallScreen && <ChatMessage />}
					{isSmallScreen && <NavContainer />}
				</Main>
				{!isSmallScreen && <Directory />}
			</Div>
		</>
	);
};

export default Dashboard;

const Div = styled.div`
	display: flex;
	font-family: "Inter", sans-serif;
	background-color: var(--bg-200);

	@media (max-width: ${theme.breakpoints.sm}) {
		height: 100vh;
	}
`;

const Main = styled.div`
	background-color: var(--bg-200);
	height: 100vh;
	width: 100vw;
	transition: 0.2s cubic-bezier(0.36, -0.01, 0, 0.77);
`;

const LoadingOverlay = styled.div`
	z-index: 1000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--bg-200);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 3rem;
	color: green;
	font-family: "Inter", sans-serif;
`;
