import React, { useState, useContext } from "react";
import styled from "styled-components";
import Logo from "../../assets/Logo.png";
import { theme } from "../Theme";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../App";
import { ChatboxContext } from "../dashboard/ChatContext";

const Login = () => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [loginError, setLoginError] = useState(null);
	const navigate = useNavigate();
	const loc = window.location.search;
	const { list } = useContext(ChatboxContext);

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const login = await window.sdkInstance.login(username, password);

			if (login) {
				console.log(login);
			} else {
				setLoginError(
					"Authentication failed, please check credentials and try again"
				);
			}
		} catch (err) {
			console.log(err);
			setLoginError("An unexpected error occurred. Please try again later.");
		}
	};

	const handleForgotPassword = () => {
		navigate(`/password/${loc}`);
	};

	return (
		<Div>
			<h1>Login</h1>
			{loginError && <ErrorMessage>{loginError}</ErrorMessage>}
			<form onSubmit={handleSubmit}>
				<FormContainer>
					<Input
						type="text"
						placeholder="Username"
						value={username}
						onChange={(e) => setUsername(e.target.value)}
					/>
					<Input
						type="password"
						placeholder="Password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</FormContainer>
				<Bottom>
					<Button type="submit">
						<p>Log in</p>
					</Button>
				</Bottom>
				<Terms>
					<p>
						By clicking login, you agree to Benefits Buddy{" "}
						<a
							href="appstaging.benefitsbuddy.com/tandc"
							target="_blank"
							rel="noopener"
						>
							Terms and Conditions
						</a>
					</p>
					<span onClick={handleForgotPassword}>Forgot Password</span>
				</Terms>
			</form>
			{/* <FooterCont>
				<Power>Powered by</Power>
				<Img src={Logo} alt="Logo" className="img" />
			</FooterCont> */}
		</Div>
	);
};

export default Login;

const Div = styled.div`
	background-color: #fdfffc;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;

	h1 {
		font-weight: bolder;
	}
`;

const FooterCont = styled.div`
	display: flex;
	align-items: center;
	position: absolute;
	bottom: 2rem;
`;

const Power = styled.p`
	font-size: 0.6rem;
	margin-right: 0.5rem;
	margin-top: 2.6rem;
`;

const Img = styled.img`
	width: 14vh;
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
`;

const Input = styled.input`
	border: none;
	outline: none;
	background-color: #d9d9d9;
	border-radius: 0.3rem;
	padding: 0.7rem;
	margin: 1rem;
	width: 25rem;
	font-size: 16px !important;

	@media screen and (max-width: ${theme.breakpoints.sm}) {
		width: 17rem;
	}
`;

const ErrorMessage = styled.span`
	color: var(--text-500);
	background-color: rgba(255, 99, 71, 0.3);
	border-left: solid red 3px;
	padding: 0.5rem;
	margin-top: 0.5rem;
	display: inline-block;
	width: 25vh;
	text-align: center;
`;

const Bottom = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const Button = styled.button`
	width: 10rem;
	height: 2.4rem;
	border-radius: 0.3rem;
	border: none;
	outline: none;
	background-color: #02ffb3;
	font-size: 1rem;
	margin: 1rem;
	cursor: pointer;
`;

const Terms = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.8;

	p {
		font-size: 0.7rem;
		width: 13rem;
		text-align: center;
		line-height: 1.5;
	}

	a {
		text-decoration: underline;
	}

	span {
		font-size: 0.7rem;
		margin-top: 1rem;
		color: var(--text-200);
		cursor: pointer;
	}
`;
