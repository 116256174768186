import React, { useContext } from "react";
import { RxStack } from "react-icons/rx";
import styled from "styled-components";
import { FaArrowRightToBracket } from "react-icons/fa6";
import { ChatboxContext } from "../ChatContext";

const RelatedQuestions = ({ assistantContent }) => {
	const { setPrompt, submitButtonDisabled, setSubmitButtonDisabled } =
		useContext(ChatboxContext);

	const handleQuestionsClick = (questions) => {
		setPrompt(questions);
		setSubmitButtonDisabled(!submitButtonDisabled);
	};

	return (
		<Main>
			{assistantContent.button_groups.map((group, index) => (
				<InnerContainer key={index}>
					{group.title === "Suggestions" && (
						<div>
							<Header>
								<Stack />
								<h4>Related Questions</h4>
							</Header>
							{group.buttons.map((buttonGroup, i) => (
								<ButtonContainer key={i}>
									<Buttons
										disabled={submitButtonDisabled}
										onClick={() =>
											handleQuestionsClick(
												buttonGroup.prompt
													? buttonGroup.prompt
													: buttonGroup.text
											)
										}
									>
										<p>{buttonGroup.text}</p>
										<Arrow />
									</Buttons>
								</ButtonContainer>
							))}
						</div>
					)}
				</InnerContainer>
			))}
		</Main>
	);
};

export default RelatedQuestions;

const Main = styled.div`
	width: 100%;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	padding: 1rem 0.5rem;
	border-top: solid var(--text-200) 1px;
`;

const Stack = styled(RxStack)`
	margin-right: 0.4rem;
	font-size: 1.2rem;
`;

const ButtonContainer = styled.div`
	border-top: solid var(--text-200) 1px;
	width: 100%;

	&:hover {
		border-top: solid var(--accent-100) 1px;
	}
`;

const Buttons = styled.button`
	background-color: transparent;
	color: var(--text-100);
	padding: 0 0.9rem;
	text-align: start;
	line-height: 1.4;
	border: none;
	width: 100%;
	box-sizing: border-box;
	font-size: 0.8rem;
	transition: background-color 0.3s ease, border-radius 0.3s ease;
	display: flex;
	justify-content: space-between;
	gap: 1rem;
	align-items: center;
	cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

	&:hover {
		background-color: grey;
		width: 100%;
	}
`;

const Arrow = styled(FaArrowRightToBracket)`
	font-size: 1rem;
	align-self: center;
`;

const InnerContainer = styled.div``;
