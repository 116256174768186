import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ChatHistoryId from "./ChatHistoryId";
import { Link } from "react-router-dom";
import { BiLeftArrowAlt } from "react-icons/bi";
import { theme } from "../../Theme";

const ChatHistory = () => {
	const [history, setHistory] = useState([]);
	const [selectedConversation, setSelectedConversation] = useState(null);

	const handleConversationClick = async (conversationId) => {
		try {
			const convoById = await window.sdkInstance.agent.getConversation(
				conversationId
			);
			setSelectedConversation(convoById);
		} catch (error) {
			console.error(error);
		}
	};

	const summary = async () => {
		try {
			const convsersationSummary =
				await window.sdkInstance.agent.getConversationSummary();
			setHistory(convsersationSummary.conversations);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		summary();
	}, []);

	const formatDate = (dateTimeString) => {
		const dateTime = new Date(dateTimeString);
		const date = dateTime.toDateString();
		const time = dateTime.toTimeString().split(" ")[0];
		return { date, time };
	};

	const sortedMessages = [...history].sort(
		(a, b) => new Date(b.created_at) - new Date(a.created_at)
	);
	return (
		<Main>
			{selectedConversation ? (
				<ChatHistoryId
					conversation={selectedConversation}
					goBack={() => setSelectedConversation(null)}
				/>
			) : (
				<>
					<History>
						<LinkContainer>
							<Link to="/dashboard" className="link">
								<BiLeftArrowAlt />
							</Link>
						</LinkContainer>
						<div>
							<Ul>
								{sortedMessages.map((item) => {
									const titleLimit = 27;
									const truncatedTitle =
										item.top_message?.content?.slice(0, titleLimit) +
										(item.top_message?.content?.length > titleLimit
											? "..."
											: "");

									return (
										<div key={item.id}>
											{item.top_message?.type === "user" && (
												<li onClick={() => handleConversationClick(item.id)}>
													<ContentWrapper>
														<p className="title">{truncatedTitle}</p>
														<Content>
															<p>{formatDate(item.created_at).date}</p>
															<p>{formatDate(item.created_at).time}</p>
														</Content>
													</ContentWrapper>
												</li>
											)}
										</div>
									);
								})}
							</Ul>
						</div>
					</History>
				</>
			)}
		</Main>
	);
};

export default ChatHistory;

const Main = styled.div`
	background: var(--bg-200);
	color: var(--text-100);
	width: 100vw;
	height: 100vh;
`;

const History = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const LinkContainer = styled.div`
	font-size: 1.3rem;
	color: var(--text-200);
	position: absolute;
	top: 6rem;
	left: 1rem;
	transition: color 0.3s ease, border-radius 0.3s ease;

	&:hover {
		color: var(--accent-100);
	}
`;

const Ul = styled.ul`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: scroll;
	height: 90vh;
	width: 80vw;
	margin-top: 2rem;

	li {
		cursor: pointer;
		border: solid var(--text-200) 1px;
		margin: 1.4rem;
		height: 5rem;
		width: 55vw;
		border-radius: 0.6rem;
		list-style: none;
		padding: 1rem;
		font-size: 1rem;
		transition: background-color 0.3s ease, border-radius 0.3s ease;

		&:hover {
			background-color: lightgrey;
			color: var(--text-500);
		}
		@media (max-width: ${theme.breakpoints.xs}),
			(max-width: ${theme.breakpoints.sm}) {
			font-size: 0.8rem;
			width: 90vw;
		}

		@media (max-width: ${theme.breakpoints.md}) {
			width: 69vw;
			margin: 1.1rem 0.9rem;
		}
	}
`;

const ContentWrapper = styled.div`
	.title {
		font-weight: 900;
	}
`;

const Content = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	height: 2.5rem;
	color: var(--text-200);
`;
