import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Typed from "typed.js";
import AI from "../../assets/AI.png";

const TypedText = () => {
	// const el = useRef(null);

	// useEffect(() => {
	// 	const text = [
	// 		"What is my plan sponsor?",
	// 		"Do I have PTO?",
	// 		"Do I have sick leave?",
	// 		"How many hours is considered full time?",
	// 		"What is our vacation policy?",
	// 		"Explain maternity leave",
	// 		"How does our health insurance coverage work?",
	// 		"What is the protocol for requesting a leave of absence?",
	// 	];
	// 	const typed = new Typed(el.current, {
	// 		strings: text,
	// 		typeSpeed: 30,
	// 		parseHtml: true,
	// 		fadeOut: true,
	// 		backSpeed: 30,
	// 		backDelay: 2000,
	// 		cursorChar: "●",
	// 		autoInsertCss: true,
	// 		showCursor: true,
	// 		loop: true,
	// 		loopCount: Infinity,
	// 	});
	// 	return () => {
	// 		typed.destroy();
	// 	};
	// }, []);

	return (
		<div>
			{/* <ImageContainer>
				<img src={AI} alt="AI" className="img" />
				<h4 className="title">Benefits Buddy</h4>
			</ImageContainer> */}
			<Text>
				<span className="typed-text">
					{/* <span ref={el} /> */}
				</span>
			</Text>
		</div>
	);
};

export default TypedText;

const Text = styled.div`
	background-color: #0a100d;
	height: 100vh;
	width: 45vw;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: "Inter", sans-serif;

	.typed-text {
		width: 40vw;
		margin-left: 1rem;
		display: inline-block;
		vertical-align: middle;

		span {
			font-size: 2rem;
			display: inline;
		}
	}
`;

const ImageContainer = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	top: 3rem;
	left: 1rem;
	height: 0vh;

	.img {
		border-radius: 0.4rem;
		height: 5vh;
		width: 5vh;
	}

	.title {
		margin-left: 0.7rem;
		color: white;
	}
`;
