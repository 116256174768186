export class Auth {
    constructor(sdkInstance) {
        this.sdkInstance = sdkInstance;
        this.config = sdkInstance.config;
    }

    async start() {
        this.clientsList = await this.getClientsList();
        if(this.clientsList) {
           this.sdkInstance.triggerSuccess('auth', 'clientsList', this.clientsList);
        }

        await this.checkOauthResponse();
    }

    async getClientsList() {
        try {
            const response = await fetch(`${this.config.backendPath}/api/auth/clients`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log(`status: ${response.status}`);
            const responseJson = await response.json();
        
            if (!response.ok) {
                throw new Error(JSON.stringify(responseJson));
            }
    
            return responseJson;
        }
        catch(error) {
            console.error(`error on get clients - ${error.message}`);
            this.sdkInstance.triggerError('auth', 'clientsList', error.message);
        }
    }

    async checkOauthResponse() {
        const queryParams = new URLSearchParams(this.config.queryParams);
        if(queryParams.has('oauth_response') && queryParams.has('error')) {
            console.warn(`checkOauthResponse, found error: ${JSON.stringify(Object.fromEntries(queryParams))}`);
            this.sdkInstance.triggerError('auth', 'oauthResponse', `Error for client ${queryParams.get('oauth_response')} of ${queryParams.get('error')} and description ${queryParams.get('error_description')}`);
        } else if(queryParams.has('oauth_response') && queryParams.has('code')) {
            const queryParamsObj = Object.fromEntries(queryParams);
            console.info(`checkOauthResponse, found success: ${JSON.stringify(queryParamsObj)}`);
            await this.oauthCodeExchange(queryParamsObj);
        } else {
            try {
                const authToken = localStorage.getItem('token');
                if(authToken) {
                    return await this.processLoginResponse({
                        ok: true
                    }, {
                        token: authToken
                    });
                }
            } catch(error) {
                //console.warn(`processLoginResponse local storage get error: ${error.message}`);
            }
            this.sdkInstance.triggerSuccess('auth', 'noOauthResponse');
        }
    }

    async oauthCodeExchange(body) {
        try {
            const response = await fetch(`${this.config.backendPath}/api/auth/oauthCodeExchange`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            const responseJson = await response.json();
        
            await this.processLoginResponse(response, responseJson);
        }
        catch(error) {
            console.error(`error on oauthCodeExchange - ${error.message}`);
            this.sdkInstance.triggerError('auth', 'login', error.message);
        }
    }

    async login(username, password, client) {
        try {
            const response = await fetch(`${this.config.backendPath}/api/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username,
                    password,
                    client
                })
            });
            const responseJson = await response.json();
        
            await this.processLoginResponse(response, responseJson);
        }
        catch(error) {
            console.error(`error on login - ${error.message}`);
            this.sdkInstance.triggerError('auth', 'login', error.message);
        }
    }

    async logout() {
        try {
            localStorage.removeItem('token');
            this.sdkInstance.config.userData = null;
            this.sdkInstance.config.authToken = null;
        } catch(error) {
            //console.warn(`processLoginResponse local storage remove error: ${error.message}`);
        }
    }

    async disclaimer(status) {
        try {
            console.log(`sending disclaimer: ${status}`);
            console.log(`path: ${this.config.backendPath}/api/auth/disclaimer`);
            const response = await fetch(`${this.config.backendPath}/api/auth/disclaimer`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${this.config.authToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    status: status
                })
            });
            console.log(`disclaimer status: ${response.status}`);
        } catch(error) {
            console.log(`error: ${error.message}`);
        }
    }

    async forgotPassword(username, client) {
        try {
            console.log(`sending forgot-password: ${username} ${client}`);
            console.log(`path: ${this.config.backendPath}/api/auth/forgot-password`);
            const response = await fetch(`${this.config.backendPath}/api/auth/forgot-password`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${this.config.authToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    client: client
                })
            });
            console.log(`forgot-password status: ${response.status}`);
        } catch(error) {
            console.log(`error: ${error.message}`);
        }
    }

    async bulkImport(chunk) {
        try {
            console.log(`sending bulk import chunk: ${JSON.stringify(chunk)}`);
            console.log(`path: ${this.config.backendPath}/api/auth/bulkImport`);
            const response = await fetch(`${this.config.backendPath}/api/auth/bulkImport`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${this.config.authToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    users: chunk
                })
            });
            console.log(`bulkImport status: ${response.status}`);
        } catch(error) {
            console.log(`error: ${error.message}`);
        }
    }

    async processLoginResponse(response, responseJson) {
        if (!response.ok) {
            if(responseJson.error) {
                return this.sdkInstance.triggerError('auth', 'login', responseJson.error);
            }
            throw new Error(JSON.stringify(responseJson));
        }
    
        const authToken = responseJson.token;
    
        const meResponse = await fetch(`${this.config.backendPath}/api/auth/me`, {
            headers: {
                Authorization: `Bearer ${authToken}`
            }
        });
        const meResponseJson = await meResponse.json();
    
        if (!meResponse.ok) {
            if(meResponseJson.error) {
                return this.sdkInstance.triggerError('auth', 'login', meResponseJson.error);
            }
            throw new Error(JSON.stringify(meResponseJson));
        }
    
        const userData = meResponseJson;

        try {
            localStorage.setItem('token', authToken);
        } catch(error) {
            //console.warn(`processLoginResponse local storage error: ${error.message}`);
        }
    
        this.sdkInstance.triggerSuccess('auth', 'login', {
            authToken,
            userData
        });
    }

    oauthClientLoginRequest(clientName) {
        console.info(`oauthClientLoginRequest for ${clientName}`);
        for(let client of this.clientsList) {
            if(client.name === clientName) {
                const route = client.auth_route;
                return this.sdkInstance.triggerSuccess('auth', 'locationChange', route);
            }
        }

        this.sdkInstance.triggerError('auth', 'oauthClientLoginRequest', `client with name ${clientName} not found`);
    }
}