import React, { useContext, useEffect } from "react";
import { ChatboxContext } from "../ChatContext";
import styled from "styled-components";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { IoCloseCircleOutline } from "react-icons/io5";
import Iframe from "react-iframe";

const VideoIframe = () => {
	const { video, setVideo, isModalOpen, setIsModalOpen, setVideoError } =
		useContext(ChatboxContext);

	const fullScreen = async () => {
		try {
			const container = document.getElementById("frameContainer");
			if (container) {
				if (!document.fullscreenElement) {
					await container.requestFullscreen();
				} else {
					document.exitFullscreen();
				}
			}
		} catch (err) {
			console.error(`requesting full screen error: ${err.message}`);
		}
	};

	useEffect(() => {
		if (video) {
			setIsModalOpen(true);
		}
	}, [setIsModalOpen, video]);

	const closeModal = () => {
		setIsModalOpen(false);
		setVideo(null);
	};

	try {
		return (
			<>
				{isModalOpen && video ? (
					<FrameContainer id="frameContainer">
						<CloseIcon onClick={closeModal} />
						{document.fullscreenElement ? (
							<ExitFullScreen onClick={fullScreen} />
						) : (
							<FullScreen onClick={fullScreen} />
						)}
						<StyledIframe
							src={video.url}
							title={video.cmd}
							id={video.sessionId}
							frameBorder="0"
							allowFullScreen
							onError={(e) => {
								console.error("Iframe load error: ", e);
								setVideoError("Error loading video");
							}}
						/>
					</FrameContainer>
				) : null}
			</>
		);
	} catch (error) {
		console.error("Error rendering video content: ", error);
		setVideoError("Error rendering video content");
	}
};

export default VideoIframe;

const FrameContainer = styled.div`
	width: 70vw;
	height: 76vh;
	position: absolute;
	z-index: 10;
	margin-top: .9rem;
	background-color: var(--bg-300);
	border-radius: 0.4rem;

	@media (max-width: 1100px) {
		height: 69vh;
		width: 90vw;
	}
	@media (max-width: 414px) {
		height: 68vh;
	}

	@media (max-width: 375px) {
		height: 60vh;
	}
`;

const StyledIframe = styled(Iframe)`
	position: relative;
	top: 10%;
	left: 10%;
	width: 80%;
	height: 80%;
`;

const CloseIcon = styled(IoCloseCircleOutline)`
	color: var(--text-100);
	font-size: 1.3rem;
	z-index: 100;
	position: absolute;
	right: 0rem;
	margin: 0.9rem;

	&:hover {
		color: var(--text-300);
		cursor: pointer;
	}
`;

const FullScreen = styled(AiOutlineFullscreen)`
	color: var(--text-100);
	font-size: 1.1rem;
	z-index: 100;
	position: absolute;
	right: 1.7rem;
	top: 0.1rem;
	margin: 0.9rem;

	&:hover {
		color: var(--text-300);
		cursor: pointer;
	}
`;

const ExitFullScreen = styled(AiOutlineFullscreenExit)`
	color: var(--text-100);
	font-size: 1.1rem;
	z-index: 100;
	position: absolute;
	right: 1.7rem;
	top: 0.1rem;
	margin: 0.9rem;

	&:hover {
		color: var(--text-300);
		cursor: pointer;
	}
`;
