import React, { useState, useContext, useEffect } from "react";
import { ChatboxContext } from "../ChatContext";
import styled, { keyframes } from "styled-components";
import { VscClose } from "react-icons/vsc";
import { BsDownload } from "react-icons/bs";
import { FaCheck } from "react-icons/fa6";
import { theme } from "../../Theme";
import Video from "./Video";
import Tooltip from "@mui/material/Tooltip";
import { IoEyeSharp } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";

const FileContainer = () => {
	const [selected, setSelected] = useState("files");

	const {
		isPopupOpen,
		setIsPopupOpen,
		isDownload,
		setIsDownload,
		isVisible,
		setIsVisible,
		filesList,
		setFilesList,
		downloadStatus,
		setDownloadStatus,
		setPrompt,
		setShowMenu,
		selectedOption,
	} = useContext(ChatboxContext);

	useEffect(() => {
		setIsVisible(null);
		if (downloadStatus === "success" || downloadStatus === "error") {
			setIsVisible(true);

			const timeoutId = setTimeout(() => {
				setIsVisible(false);
				setDownloadStatus(null);
			}, 5000);

			return () => {
				clearTimeout(timeoutId);
			};
		}
	}, [downloadStatus]);

	useEffect(() => {
		const id = setTimeout(() => {
			if (!filesList.length)
				window.sdkInstance.file.getFileList().then((fileList) => {
					setFilesList(fileList.files);
				});
		}, 1000);
		return () => {
			clearTimeout(id);
		};
	}, [filesList.length]);

	const handleDownload = async (fileKey) => {
		try {
			const fileList = await window.sdkInstance.file.getFileList();

			if (fileList && fileList.files && fileList.files.length > 0) {
				const file = fileList.files.find((f) => f.Key === fileKey);

				if (file) {
					setIsPopupOpen(!isPopupOpen);
					const link = document.createElement("a");
					link.href = file.signedUrl;
					link.download = `${file.Key}.pdf`;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					setDownloadStatus("success");
					setIsDownload(isDownload);
				} else {
					console.error(`File with title ${fileKey} not found.`);
					setIsDownload("error");
					setDownloadStatus("error");
				}
			} else {
				console.error("Files array is empty or undefined.");
				setIsDownload("error");
				setDownloadStatus("error");
			}
		} catch (error) {
			console.error(error);
			setIsDownload("error");
			setDownloadStatus("error");
		} finally {
			setIsPopupOpen(false);
		}
	};
	const openModal = (fileUrl) => {
		setPrompt(fileUrl);
		setShowMenu(false);
	};

	const charactersLimit = 25;
	const isSmallScreen = useMediaQuery({ maxWidth: 1100 });

	return (
		<>
			<div className="file-container">
				<ToggleButtonContainer>
					<ToggleButton onClick={() => setSelected("files")}>
						Files
					</ToggleButton>
					<ToggleButton onClick={() => setSelected("video")}>
						Videos
					</ToggleButton>
				</ToggleButtonContainer>
			</div>
			<First>
				<FileContainers>
					{selected === "files" ? (
						filesList
							.filter((item) => item.signedUrl)
							.map((item) => {
								const characters =
									item.title.length > charactersLimit
										? item.title.slice(0, charactersLimit) + "..."
										: item.title;

								const fileUrl =
									selectedOption === "viewInline"
										? item.viewInline
										: selectedOption === "viewInTab"
										? item.viewTab
										: item.viewPopup;

								return (
									<div key={item.Key} className="main">
										<div className="download">
											<Tooltip
												title="View"
												placement="top-start"
												slotProps={{
													tooltip: {
														sx: {
															backgroundColor: "var(--accent-100)",
															color: "black",
															borderRadius: ".2rem",
															fontSize: ".8rem",
														},
													},
												}}
											>
												<div>
													<IoEyeSharp
														onClick={() => openModal(fileUrl)}
														className="eye"
													/>
												</div>
											</Tooltip>
											<Tooltip
												title="Download"
												placement="top-start"
												slotProps={{
													tooltip: {
														sx: {
															backgroundColor: "var(--accent-100)",
															color: "black",
															borderRadius: ".2rem",
															fontSize: ".8rem",
														},
													},
												}}
											>
												<div>
													<BsDownload
														onClick={() => {
															setIsPopupOpen(true);
															setIsDownload(item.Key);
														}}
														className="down"
													/>
												</div>
											</Tooltip>
										</div>
										<Tooltip
											title={item.title}
											placement="top-start"
											slotProps={{
												tooltip: {
													sx: {
														backgroundColor: "var(--accent-100)",
														color: "black",
														borderRadius: ".2rem",
														fontSize: ".8rem",
													},
												},
											}}
										>
											<FileTitle>
												<ul>
													{isSmallScreen ? (
														<li>{item.title}</li>
													) : (
														<li>{characters}</li>
													)}
												</ul>
											</FileTitle>
										</Tooltip>
									</div>
								);
							})
					) : (
						<Video />
					)}
				</FileContainers>
			</First>
			{isPopupOpen && (
				<PopupContainer className="popup">
					<div className="header-container">
						<p>Please confirm your decision</p>
						<p>to download this file.</p>
						<div className="buttons-container">
							<button onClick={() => handleDownload(isDownload)}>
								Confirm and Proceed
							</button>
							<button onClick={() => setIsPopupOpen(false)}>Cancel</button>
						</div>
					</div>
				</PopupContainer>
			)}
			<StatusContainer isVisible={isVisible}>
				<div>
					{downloadStatus === "success" ? (
						<div>
							Download Successful
							<Check />
						</div>
					) : downloadStatus === "error" ? (
						<div>
							Download Error
							<Close />
						</div>
					) : null}
				</div>
			</StatusContainer>
		</>
	);
};

export default FileContainer;

const ToggleButton = styled.button`
	padding: 0.8rem;
	border: solid grey 1px;
	border-radius: 0.3rem;
	margin: 0.6rem 0.4rem 0rem;
	height: 2.5rem;
	width: 6rem;
	background-color: transparent;
	color: var(--text-100);
	cursor: pointer;
	transition: background-color 0.3s ease, border-radius 0.3s ease;

	&:hover {
		background-color: var(--accent-100);
		color: var(--text-500);
	}
`;

const ToggleButtonContainer = styled.div`
	width: fit-content;
	margin-top: 2rem;
`;

const FileContainers = styled.div`
	height: 24rem;
	overflow-y: scroll;
	margin-bottom: 1rem;
	margin-top: 1rem;

	@media (max-width: ${theme.breakpoints.sm}) {
		height: 15rem;
	}

	@media screen and (max-width: 1100px) {
		border: solid darkgray 1px;
		padding: 0.4rem;
		border-radius: 0.5rem;
		width: 23rem;
	}

	&::-webkit-scrollbar {
		width: 0.5em;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
	}

	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
`;

const First = styled.div`
	font-size: 0.9rem;

	.main {
		transition: background-color 0.3s ease, border-radius 0.3s ease;

		&:hover {
			background-color: lightgrey;
			color: var(--text-500);
		}

		margin-bottom: 1rem;
		border-radius: 0.5rem;
		display: flex;
		padding: 0.8rem;
		gap: 1rem;
		width: 18rem;
		overflow-x: hidden;

		.download {
			gap: 0.9rem;
			cursor: pointer;
			display: flex;

			.eye,
			.down,
			.pop,
			.tab {
				&:hover {
					color: var(--accent-100);
				}
			}
		}

		@media screen and (max-width: ${theme.breakpoints.xs}) {
			justify-content: flex-start;
		}

		@media screen and (max-width: 937px) {
			width: 15rem;
		}

		@media screen and (max-width: 1100px) {
			width: 14.5rem;
			gap: 0.7rem;
		}

		@media screen and (max-width: 1200px) {
			gap: 0.7rem;
		}
	}

	@media screen and (max-width: 937px) {
		font-size: 0.8rem;
	}

	@media screen and (max-width: 1100px) {
		font-size: 0.8rem;
	}
`;

const FileTitle = styled.div`
	ul {
		width: 20rem;
	}

	li {
		list-style: none;
	}
`;

const Check = styled(FaCheck)`
	font-size: 0.8rem;
	margin-top: 0.1rem;
	margin-left: 0.15rem;
`;

const Close = styled(VscClose)`
	font-size: 0.8rem;
	margin-top: 0.1rem;
	margin-left: 0.15rem;
	color: var(--txt-500);
`;

const StatusContainer = styled.div`
	z-index: 100;
	position: fixed;
	top: 4.3rem;
	right: 0;
	width: 15rem;
	background-color: var(--accent-100);
	color: var(--text-500);
	padding: 0.5rem;
	text-align: center;
	text-align: center;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	transform: ${({ isVisible }) =>
		isVisible ? "translateX(0)" : "translateX(100%)"};
	animation: ${({ isVisible }) => (isVisible ? fadeIn : fadeOut)} 3s ease-in
		forwards;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 0;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
`;

const PopupContainer = styled.div`
	background-color: var(--accent-100);
	color: var(--text-500);
	height: 13rem;
	width: 16rem;
	border-radius: 0.6rem;
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	top: 11rem;
	left: 2.4rem;

	.header-container {
		padding: 1rem;
		font-weight: 700;

		p {
			font-size: 0.9rem;
			flex-wrap: wrap;
			text-align: center;
		}

		.buttons-container {
			margin-top: 0.8rem;
			display: flex;
			flex-direction: column;
		}

		button {
			margin: 0.3em;
			background-color: var(--bg-200);
			border: none;
			color: var(--text-100);
			padding: 0.8rem;
			border-radius: 0.4rem;
			cursor: pointer;
			font-size: 0.9rem;
		}
	}

	@media (max-width: ${theme.breakpoints.xs}) {
		z-index: 1000;
		height: 8rem;
		width: 10rem;
		top: 11rem;

		.header-container {
			p {
				font-size: 0.7rem;
			}

			button {
				font-size: 0.7rem;
			}
		}
	}

	@media screen and (max-width: ${theme.breakpoints.lg}) {
		left: 0.5rem;
		bottom: 17rem;
	}

	@media screen and (max-width: ${theme.breakpoints.xxl}) {
		left: 4.5rem;
		top: 9rem;
	}

	@media screen and (max-width: 1100px) {
		left: 1.8rem;
		bottom: 16rem;
		height: 13rem;
		width: 13rem;
		font-size: 0.9rem;
	}
`;
