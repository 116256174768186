import React, { useState, useContext } from "react";
import { ChatboxContext } from "./ChatContext";
import ChatMessage from "./chat/ChatMessage";
import Directory from "./directory/Directory";
import Suggestions from "./Suggestions";
import ChatHistory from "./history/ChatHistory";
import styled from "styled-components";
import { GoHome } from "react-icons/go";
import { IoMdMenu } from "react-icons/io";
import { TbMessageQuestion } from "react-icons/tb";
import { AiOutlineHistory } from "react-icons/ai";

const NavContainer = () => {
	const [selected, setSelected] = useState("");
	const [showSuggestions, setShowSuggestions] = useState(false);
	const { showMenu, setShowMenu } = useContext(ChatboxContext);

	const handleSwitch = () => {
		switch (selected) {
			case "home":
				return <ChatMessage />;
			case "menu":
				return <ChatMessage />;
			case "suggestions":
				return <ChatMessage />;
			case "history":
				return <ChatHistory />;
			default:
				return <ChatMessage />;
		}
	};

	const iconSize = "1rem";

	return (
		<Main>
			<Buttons>
				<Button
					onClick={() => {
						setSelected("home");
						setShowSuggestions(false);
						setShowMenu(false);
					}}
					active={selected === "home" ? "true" : undefined}
				>
					<GoHome style={{ fontSize: iconSize }} />
					<ButtonText>Home</ButtonText>
				</Button>
				<Button
					onClick={() => {
						setSelected("menu");
						setShowSuggestions(false);
						setShowMenu(true);
					}}
					active={selected === "menu" ? "true" : undefined}
				>
					<IoMdMenu style={{ fontSize: iconSize }} />
					<ButtonText>Menu</ButtonText>
				</Button>
				<Button
					onClick={() => {
						setSelected("suggestions");
						setShowSuggestions(true);
						setShowMenu(false);
					}}
					active={selected === "suggestions" ? "true" : undefined}
				>
					<TbMessageQuestion style={{ fontSize: iconSize }} />
					<ButtonText>Suggestions</ButtonText>
				</Button>
				<Button
					onClick={() => {
						setSelected("history");
						setShowSuggestions(false);
						setShowMenu(false);
					}}
					active={selected === "history" ? "true" : undefined}
				>
					<AiOutlineHistory style={{ fontSize: iconSize }} />
					<ButtonText>History</ButtonText>
				</Button>
			</Buttons>
			{handleSwitch()}
			{showMenu && (
				<MenuContainer show={showMenu}>
					<Directory />
				</MenuContainer>
			)}
			{showSuggestions && (
				<SuggestionsContainer show={showSuggestions}>
					<Suggestions
						showSuggestions={showSuggestions}
						setShowSuggestions={setShowSuggestions}
					/>
				</SuggestionsContainer>
			)}
		</Main>
	);
};
export default NavContainer;

const Main = styled.div``;

const Buttons = styled.div`
	display: flex;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 4rem;
	z-index: 100;
	background-color: var(--bg-300);
	gap: 2rem;
`;

const Button = styled.button`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 4rem;
	background-color: var(--bg-300);
	color: var(--text-100);
	border: none;
	outline: none;
	cursor: pointer;
	transition: background-color 0.3s ease, border-radius 0.3s ease;

	&:hover {
		background-color: lightgrey;
		color: black;
	}
`;

const ButtonText = styled.span`
	font-size: 0.9rem;
`;

const SuggestionsContainer = styled.div`
	position: absolute;
	bottom: 4rem;
	left: 0;
	width: 100%;
	height: 85vh;
	animation: myAnim 0.4s linear 0s 1 normal forwards;
	z-index: 100;
	background: rgba(255, 255, 255, 0.25);
	backdrop-filter: blur(11px);
	-webkit-backdrop-filter: blur(11px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);

	@keyframes myAnim {
		0% {
			opacity: 0;
			transform: translateY(250px);
		}

		100% {
			opacity: 2;
			transform: translateY(0);
		}
	}
`;

const MenuContainer = styled.div`
	position: absolute;
	bottom: 4rem;
	left: 0;
	width: 100%;
	height: 85vh;
	animation: myAnim 0.4s linear 0s 1 normal forwards;
	z-index: 100;
	background: rgba(255, 255, 255, 0.25);
	backdrop-filter: blur(11px);
	-webkit-backdrop-filter: blur(11px);
	border-radius: 10px;
	border: 1px solid rgba(255, 255, 255, 0.18);
	overflow-x: hidden;

	@keyframes myAnim {
		0% {
			opacity: 0;
			transform: translateY(250px);
		}

		100% {
			opacity: 2;
			transform: translateY(0);
		}
	}
`;
