import React, { useRef, useContext } from "react";
import styled from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import { IoDocumentTextOutline } from "react-icons/io5";
import { theme } from "../../Theme";
import { IoMdClose } from "react-icons/io";
import { ChatboxContext } from "../ChatContext";

const SourcesButton = ({
	handleButtonClick,
	setIsDropdownOpen,
	isDropdownOpen,
}) => {
	const sourcesContainerRef = useRef(null);

	const { activeGroup, setActiveGroup } = useContext(ChatboxContext);

	const closeModal = () => {
		setIsDropdownOpen(false);
		setActiveGroup(null);
	};

	return (
		<Modal ref={sourcesContainerRef}>
			<ModalContent>
				{isDropdownOpen && (
					<CloseButton onClick={closeModal}>
						<IoMdClose />
					</CloseButton>
				)}
				<ButtonGrid>
					{activeGroup.buttons.map((button, i) => (
						<ButtonContainer key={i}>
							<Tooltip
								title={button.text}
								slotProps={{
									tooltip: {
										sx: {
											backgroundColor: "#ced4da",
											color: "black",
											borderRadius: ".2rem",
											fontSize: ".8rem",
										},
									},
								}}
							>
								<Button
									onClick={() => {
										handleButtonClick(
											button.prompt ? button.prompt : button.text
										);
									}}
								>
									<DocumentIcon />
									{button.text.length > 20
										? `${button.text.substring(0, 24)}...`
										: button.text}
								</Button>
							</Tooltip>
						</ButtonContainer>
					))}
				</ButtonGrid>
			</ModalContent>
		</Modal>
	);
};

export default SourcesButton;

const Modal = styled.div`
	height: auto;
	animation: myAnim 1s ease 0s 1 normal forwards;
	width: 100%;
	position: relative;

	@keyframes myAnim {
		0% {
			transform: translateY(-10px);
		}

		100% {
			transform: translateY(0);
		}
	}
`;

const ModalContent = styled.div`
	background: var(--bg-200);
	padding: 1.5rem;
	border-radius: 8px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	@media (max-width: ${theme.breakpoints.sm}) {
		padding: 0.2rem;
	}
`;

const CloseButton = styled.button`
	position: absolute;
	right: 0.5rem;
	top: 0.5rem;
	background: transparent;
	color: var(--text-100);
	border: none;
	font-size: 1.1rem;
	cursor: pointer;

	@media (max-width: ${theme.breakpoints.sm}) {
		font-size: 0.8rem;
		top: 0.2rem;
		right: 0.2rem;
	}
`;

const ButtonGrid = styled.div`
	width: 50vw;

	@media (max-width: ${theme.breakpoints.sm}) {
		width: 72vw;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
`;

const Button = styled.button`
	background: var(--accent-100);
	color: var(--text-500);
	border: none;
	padding: 0.5rem 1rem;
	border-radius: 0.2rem;
	cursor: pointer;
	height: 2.2rem;
	margin: 1rem 0.4rem;
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const DocumentIcon = styled(IoDocumentTextOutline)`
	margin-right: 0.4rem;
`;
