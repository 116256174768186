import React from "react";
import styled from "styled-components";
import { theme } from "../Theme";
import { PiQuestionLight } from "react-icons/pi";
import { BsLightningCharge } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const NavItems = () => {
	const navigateHistory = useNavigate();

	return (
		<NavMenu>
			<NavItem>
				<button className="upgrade" onClick={() => navigateHistory("/history")}>
					Chat History
				</button>
				<Lightning />
			</NavItem>
			<NavItem>
				<a
					href="mailto:supports@bnefitsbuddy.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					<p className="help">Help</p>
				</a>
				<QuestionMark />
			</NavItem>
		</NavMenu>
	);
};

export default NavItems;

const NavMenu = styled.div`
	display: flex;

	@media (max-width: ${theme.breakpoints.lg}),
		(max-width: 1100px),
		(max-width: 1000px) {
		flex-direction: column;
		align-items: flex-start;
		margin-top: 0.3rem;
	}
`;

const NavItem = styled.div`
	padding: 0.5rem 1rem;
	display: flex;
	align-items: center;
	font-size: 0.9rem;

	.upgrade {
		color: var(--accent-100);
		font-size: 0.9rem;
	}

	.grey {
		color: var(--text-200);
	}

	.help {
		color: var(--text-300);
		cursor: pointer;
		padding-right: 0.4rem;
	}

	button {
		background-color: transparent;
		color: var(--text-200);
		border: none;
		cursor: pointer;
	}

	@media (max-width: ${theme.breakpoints.lg}), (max-width: 1100px) {
		padding: 0.5rem 0;
		gap: 0.5rem;

		.upgrade {
			display: none;
		}
	}

	@media (max-width: ${theme.breakpoints.xs}),
		(max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.md}) {
		padding: 0.5rem 0;
		gap: 0.5rem;

		.upgrade {
			display: none;
		}
	}
`;

const Lightning = styled(BsLightningCharge)`
	font-size: 1.1rem;
	margin-left: 0.2rem;
	color: var(--accent-100);

	@media (max-width: ${theme.breakpoints.xs}),
		(max-width: ${theme.breakpoints.sm}),
		(max-width: ${theme.breakpoints.md}),
		(max-width: ${theme.breakpoints.lg}),
		(max-width: 1100px) {
		display: none;
	}
`;

const QuestionMark = styled(PiQuestionLight)`
	font-size: 1.2rem;
	margin-top: 0.1rem;
	color: var(--text-300);

	@media (max-width: ${theme.breakpoints.sm}) {
		margin-left: 0.2rem;
	}
`;
