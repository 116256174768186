import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { LuCopy } from "react-icons/lu";
import { LuThumbsDown } from "react-icons/lu";
import { CopyToClipboard } from "react-copy-to-clipboard";
import BadResponseForm from "./BadResponseForm";
import Tooltip from "@mui/material/Tooltip";

const Feedback = ({ combinedText, content }) => {
	const [copied, setCopied] = useState(false);
	const [showBadResponseForm, setShowBadResponseForm] = useState(false);
	const badResponseFormRef = useRef(null);

	const handleBadResponseClick = () => {
		setShowBadResponseForm(true);
	};

	useEffect(() => {
		if (showBadResponseForm && badResponseFormRef.current) {
			badResponseFormRef.current.scrollIntoView({ behavior: "smooth" });
		}
	}, [showBadResponseForm]);

	const handleCopy = () => {
		setCopied(true);
	};

	return (
		<Container>
			<InnerContainer>
				<CopyToClipboard text={content} onCopy={handleCopy}>
					<Tooltip
						title={copied ? "Copied!" : "Copy"}
						arrow
						slotProps={{
							tooltip: {
								sx: {
									"& .MuiTooltip-arrow": {
										color: "#701F85",
									},
									backgroundColor: "#701F85",
									color: "white",
									borderRadius: ".5rem",
									fontSize: ".8rem",
								},
							},
						}}
					>
						<Icons>
							<LuCopy />
						</Icons>
					</Tooltip>
				</CopyToClipboard>
				<Tooltip
					title="Bad Response"
					arrow
					slotProps={{
						tooltip: {
							sx: {
								"& .MuiTooltip-arrow": {
									color: "#701F85",
								},
								backgroundColor: "#701f85",
								color: "white",
								borderRadius: ".5rem",
								fontSize: ".8rem",
							},
						},
					}}
				>
					<Icons onClick={handleBadResponseClick}>
						<LuThumbsDown />
					</Icons>
				</Tooltip>
			</InnerContainer>
			<ResponseContainer ref={badResponseFormRef}>
				{showBadResponseForm && (
					<BadResponseForm
						combinedText={combinedText}
						closeForm={() => setShowBadResponseForm(false)}
					/>
				)}
			</ResponseContainer>
		</Container>
	);
};

export default Feedback;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	/* border: solid blue 1px; */
	width: 100%;
	/* height: 2.5rem;
	width: 3rem; */
`;

const InnerContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.6rem;
	margin-top: 1rem;
`;

const Icons = styled.div`
	font-size: 1.1rem;
	color: var(--text-200);
	cursor: pointer;
	outline: none;

	&:focus {
		outline: none;
	}

	span {
		cursor: pointer;

		&:focus {
			outline: none;
		}

		&:active {
			outline: none;
		}
	}
`;

const ResponseContainer = styled.div`
	width: 100%;
`;
