import React from "react";
import styled from "styled-components";
import VideoPreferences from "./VideoPreferences";
import { BiLeftArrowAlt } from "react-icons/bi";
import Appearance from "./Appearance";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

const Settings = () => {
	return (
		<Main>
			<Header>
				<Tooltip
					title="Back to dashboard"
					placement="top"
					slotProps={{
						tooltip: {
							sx: {
								backgroundColor: "var(--accent-100)",
								color: "black",
								borderRadius: ".2rem",
								fontSize: ".8rem",
							},
						},
					}}
				>
					<Link to="/dashboard">
						<Arrow />
					</Link>
				</Tooltip>
				<h1>Personal Settings</h1>
			</Header>
			<AppearanceContainer>
				<h3>View Preferences:</h3>
				<Appearance />
			</AppearanceContainer>
			<VideoContainer>
				<h3>Iframe Preferences:</h3>
				<VideoPreferences />
			</VideoContainer>
		</Main>
	);
};

export default Settings;

const Main = styled.div`
	background-color: var(--bg-200);
	height: 100vh;
	width: 100vw;
	color: var(--text-100);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

const Arrow = styled(BiLeftArrowAlt)`
	font-size: 1.2rem;
`;

const Header = styled.div`
	display: flex;
	align-items: center;
	gap: 2rem;
	justify-content: flex-start;
`;

const AppearanceContainer = styled.div`
	margin: 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 4rem;

	h3 {
		margin-bottom: 1rem;
	}
`;

const VideoContainer = styled.div`
	margin-top: 4rem;

	h3 {
		margin-bottom: 1rem;
	}
`;
