import React, { useContext, useState, useRef, useEffect } from "react";
import { ChatboxContext } from "../ChatContext";
import styled from "styled-components";
import { TiDocumentText } from "react-icons/ti";
import RelatedQuestions from "./RelatedQuestions";
import SourcesButton from "./SourcesButton";
import Feedback from "../chat/Feedback";

const Sources = ({ assistantContent, combinedText, content }) => {
	const sourcesContainerRef = useRef(null);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const { setPrompt, activeGroup, setActiveGroup } = useContext(ChatboxContext);

	const handleButtonClick = (buttonText) => {
		setPrompt(buttonText);
		setIsDropdownOpen(false);
	};

	const handleGroupClick = (group) => {
		setActiveGroup(group);
		setIsDropdownOpen(true);
	};

	useEffect(() => {
		if (sourcesContainerRef.current) {
			sourcesContainerRef.current.scrollTop =
				sourcesContainerRef.current.scrollHeight;
		}
	}, []);

	return (
		<Main>
			{assistantContent.button_groups.map((group, index) => (
				<>
					<SourcesButtonContainer key={index}>
						{group.title === "Sources" && (
							<DropDownContainer>
								<HeaderCont>
									<Feedback combinedText={combinedText} content={content} />
									<Text onClick={() => handleGroupClick(group)}>
										<p>{group.title}</p>
										<SourcesIcon />
									</Text>
								</HeaderCont>
								{isDropdownOpen && activeGroup && (
									<SourcesButton
										isDropdownOpen={isDropdownOpen}
										setIsDropdownOpen={setIsDropdownOpen}
										handleButtonClick={handleButtonClick}
									/>
								)}
							</DropDownContainer>
						)}
					</SourcesButtonContainer>
					{group.title === "Suggestions" && (
						<RelatedContainer>
							<RelatedQuestions assistantContent={assistantContent} />
						</RelatedContainer>
					)}
				</>
			))}
		</Main>
	);
};

export default Sources;

const Main = styled.div`
	display: flex;
	flex-direction: column;
	height: auto;
	width: auto;
`;

const DropDownContainer = styled.div`
	/* display: flex; */
	/* justify-content: flex-end; */
	/* flex-direction: column; */
	/* align-items: flex-end; */
`;

const HeaderCont = styled.div`
	display: flex;
	justify-content: space-between;
	align-self: start;
	cursor: pointer;
	/* border: solid green 1px; */
`;

const Text = styled.div`
	display: flex;
`;

const SourcesButtonContainer = styled.div`
	/* border: solid red 1px; */
`;

const SourcesIcon = styled(TiDocumentText)`
	text-align: center;
	margin-left: 0.4rem;
	margin-top: 1.1rem;
	color: var(--accent-100);
	font-size: 1.2rem;
`;

const RelatedContainer = styled.div`
	display: flex;
	justify-content: flex-start;
`;
